import React from "react";
import styled from "@emotion/styled";
import Image1 from "../images/icon-about-1.svg";
import Image2 from "../images/icon-about-2.svg";

const AppBar = () => {
  return (
    <Container>
      <Content>
        <Title>
          Take control of your data
          <br />
          and put it to work.
        </Title>
        <About>
          <Section>
            <img src={Image1} alt="Icon" style={{ marginRight: 32 }} />
            Companies spend billions to track and advertise to you. Get your
            fair share.
          </Section>
          <Section>
            <img src={Image2} alt="Icon" style={{ marginRight: 32 }} />
            Invisibly is on your side. We’re here to organize and protect your
            data, give you control, and earn you money.
          </Section>
        </About>
      </Content>
    </Container>
  );
};

export default AppBar;

const Container = styled.div`
  height: 800px;
  padding-left: 48px;
  padding-right: 48px;
  padding-top: 100px;
  display: flex;
  align-items: center;
  background-color: #1b2025;
  background-image: url(${require("../images/HeroImage.jpg")});
  background-position: top right;
  background-repeat: no-repeat;
  background-size: 1100px 800px;
`;
const Content = styled.div`
  width: calc(100%);
  max-width: calc(1344px);
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 150px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
`;
const Title = styled.h1`
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  font-size: 60px;
  line-height: 76px;
  color: #00d398;
  margin-top: 0px;
  margin-bottom: 80px;
`;
const About = styled.span`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 16px;
`;
const Section = styled.div`
  max-width: 520px;
  margin-bottom: 24px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  font-size: 17px;
  line-height: 26px;
  color: #f1f1f1;
`;
