import React, { useState, useContext, useRef, useEffect } from "react";
import { Col, Row, Box, Grid } from "jsxstyle";
import AppBar from "components/AppBar";
import CategoryCard from "components/CategoryCard";
import DemographicsCard from "components/DemographicsCard";
import Hero from "components/Hero";
import IntroCard from "components/IntroCard";
import Footer from "components/Footer";
import { motion, AnimatePresence } from "framer-motion";
import {
  DataContext,
  InterestsContext,
  Demographic,
  DemographicContext,
  AuthContext,
  ModalsContext,
} from "contexts";
import { v4 as uuid } from "uuid";
import Dialog from "components/Dialog";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Popper from "@material-ui/core/Popper";
import DataSources from "components/DataSources";
import { ReactComponent as SearchIcon } from "images/Search.svg";
import DeleteIcon from "./images/deleteIcon.svg";
import EditIcon from "./images/editIcon.svg";
import CheckIcon from "./images/checkIcon.svg";
import Checkbox from "components/Checkbox";
import { event } from "react-ga";
import { useLocalState } from "hooks";
import Signup from "components/Signup";
import Login from "components/Login";
import { triggerModifyPixel } from "pixels";

const AddCategoriesDialog = (props: {
  open: boolean;
  onClose: () => void;
  categories: Array<string>;
  onSubmit: (interests: Array<string>) => void;
  message: string;
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const [search, setSearch] = useState("");
  const [open, setOpen] = useState(false);

  const [selected, setSelected] = useState<Array<string>>([]);

  useEffect(() => {
    if (props.open) {
      inputRef.current?.focus();
    } else {
      setOpen(false);
      setSearch("");
      setSelected([]);
    }
  }, [props.open]);

  const searchCategories = props.categories
    .filter((c) => !selected.includes(c))
    .filter((c) => c.toLowerCase().includes(search.toLowerCase()))
    .splice(0, 3);

  return (
    <Dialog width={400} open={props.open} onClose={props.onClose}>
      <Col padding={16}>
        <Box
          fontFamily="Montserrat"
          fontWeight="bold"
          fontSize={20}
          letterSpacing="0.5px"
          textTransform="uppercase"
          color="#FFF"
          alignSelf="center"
        >
          ADD MORE CATEGORIES
        </Box>

        <Box
          fontFamily="Open Sans"
          fontSize={14}
          color="#D6DAE1"
          alignSelf="center"
          textAlign="center"
          marginTop={24}
        >
          {props.message}
        </Box>

        <ClickAwayListener onClickAway={() => setOpen(false)}>
          <div>
            <Row marginTop={24}>
              <Box
                width="auto"
                display="block"
                color="#00D398"
                fontSize={15}
                fontWeight={600}
                lineHeight="22px"
                borderRadius={20}
              >
                {selected.join(" - ")}
              </Box>
            </Row>
            <Row
              height={44}
              borderRadius={10}
              backgroundColor="#323843"
              alignItems="center"
              paddingH={16}
              marginTop={32}
              props={{
                ref: ref,
              }}
            >
              <SearchIcon width={24} height={24} />
              <Box
                marginLeft={18}
                component="input"
                backgroundColor="transparent"
                outline="none"
                textShadow="none"
                border="none"
                color="#FFFFFF"
                fontSize={15}
                props={{
                  placeholder: "Search for categories",
                  ref: inputRef,
                  value: search,
                  onFocus: () => setOpen(true),
                  onChange: (e: React.ChangeEvent<HTMLInputElement>) =>
                    setSearch(e.target.value),
                  onKeyDown: (e: React.KeyboardEvent<HTMLInputElement>) => {
                    if (e.keyCode === 27) {
                      setOpen(false);
                    }
                  },
                }}
              />
            </Row>

            <Row
              width="50%"
              height={40}
              marginLeft="50%"
              backgroundColor="#20252C"
              hoverBackgroundColor="#1B2025"
              transitionDuration="300ms"
              borderRadius={10}
              border="2px solid #00D398"
              justifyContent="center"
              alignItems="center"
              marginTop={150}
              marginBottom={0}
              cursor="pointer"
              props={{
                onClick: () => {
                  props.onSubmit(selected);
                  setSelected([]);
                  setSearch("");
                  props.onClose();
                },
              }}
            >
              <Box
                fontFamily="Open Sans"
                fontSize={14}
                fontWeight={600}
                textTransform="uppercase"
                letterSpacing="0.2px"
                color="#00D398"
              >
                Add
              </Box>
            </Row>

            <Popper
              open={open}
              anchorEl={ref.current}
              placement="bottom-start"
              style={{
                zIndex: 4000,
              }}
              transition
            >
              <Col
                backgroundColor="#323843"
                width={368}
                borderRadius={10}
                marginTop={4}
                overflow="hidden"
                boxShadow="0px 4px 30px rgba(0, 0, 0, 0.25)"
              >
                {[selected, searchCategories].flat().map((category) => (
                  <Row
                    height={44}
                    hoverBackgroundColor="#272C35"
                    key={category}
                    alignItems="center"
                    paddingH={16}
                    cursor="pointer"
                    props={{
                      onClick: () => {
                        if (selected.includes(category)) {
                          setSelected((s) => s.filter((c) => c !== category));
                        } else {
                          setSelected([...selected, category]);
                        }
                        inputRef.current?.focus();
                      },
                    }}
                  >
                    <Checkbox checked={selected.includes(category)} />
                    <Box
                      fontFamily="Montserrat"
                      fontSize={14}
                      color="#FFF"
                      marginLeft={16}
                    >
                      {category}
                    </Box>
                  </Row>
                ))}
              </Col>
            </Popper>
          </div>
        </ClickAwayListener>
      </Col>
    </Dialog>
  );
};

const MyProfileCard = () => {
  const interestsContext = useContext(InterestsContext);
  const demographicContext = useContext(DemographicContext);

  const [openAddCategoriesLike, setOpenAddCategoriesLike] = useState(false);
  const [openAddCategoriesDislike, setOpenAddCategoriesDislike] = useState(
    false
  );
  return (
    <>
      <Col
        backgroundColor="#1B2025"
        boxShadow="0px 4px 40px rgba(0, 0, 0, 0.15)"
        borderRadius={16}
        overflow="hidden"
      >
        <Row
          height={64}
          backgroundColor="#14171B"
          paddingLeft={32}
          paddingRight={28}
          alignItems="center"
        >
          <Box
            fontFamily="Montserrat"
            fontWeight={600}
            fontSize={18}
            color="#FFF"
            letterSpacing="0.3px"
          >
            My Profile
          </Box>
        </Row>
        <Col paddingH={32} paddingV={48}>
          <Box
            maxWidth="50%"
            fontFamily="Open Sans"
            fontSize={16}
            lineHeight="23px"
            marginTop={-16}
            marginBottom={20}
            color="#D6DAE1"
          >
            Own your personal data and decide what you want to share. Your
            verified information will attract more brands interested in paying
            you to see their message.
          </Box>
          <Box
            height={1}
            width="100%"
            backgroundColor="#323843"
            marginBottom={48}
          />
          <Row
            width="100%"
            justifyContent="space-between"
            alignItems="flex-end"
          >
            <Col width="50%">
              <Box
                fontFamily="Montserrat"
                fontWeight={600}
                fontSize={18}
                color="#FFFFFF"
                letterSpacing="0.3px"
                textTransform="uppercase"
              >
                Demographics
              </Box>
              <Box
                fontFamily="Open Sans"
                fontSize={16}
                lineHeight="23px"
                marginTop={4}
                color="#D6DAE1"
              >
                Accurate data is worth more. Is this correct?
              </Box>
            </Col>
            <Row>
              <Box
                alignItems="center"
                fontFamily="Open Sans"
                lineHeight="23px"
                fontSize={14}
                fontWeight={600}
                color="#FFFFFF"
              >
                References:
              </Box>
              <Row
                alignItems="center"
                fontFamily="Open Sans"
                lineHeight="23px"
                fontSize={14}
                color="#ADB3BC"
              >
                <img
                  style={{ marginRight: 10, marginLeft: 24 }}
                  src={EditIcon}
                  alt="Edit Icon"
                />
                You’re wrong, let me tell you.
              </Row>
              <Row
                alignItems="center"
                fontFamily="Open Sans"
                lineHeight="23px"
                fontSize={14}
                color="#ADB3BC"
              >
                <img
                  style={{ marginRight: 10, marginLeft: 24 }}
                  src={CheckIcon}
                  alt="Edit Icon"
                />
                Yes.
              </Row>
              <Row
                alignItems="center"
                fontFamily="Open Sans"
                lineHeight="23px"
                fontSize={14}
                color="#ADB3BC"
              >
                <img
                  style={{ marginRight: 10, marginLeft: 24 }}
                  src={DeleteIcon}
                  alt="Edit Icon"
                />
                Don't track.
              </Row>
            </Row>
          </Row>

          <Grid
            marginTop={24}
            gridTemplateColumns="1fr 1fr 1fr"
            gridTemplateRows="44px"
            gridRowGap={16}
            gridColumnGap={32}
          >
            {demographicContext.items.map((item) => (
              <DemographicsCard key={item.id} item={item} />
            ))}
          </Grid>
          <Box
            fontFamily="Montserrat"
            fontWeight={600}
            fontSize={18}
            color="#FFFFFF"
            letterSpacing="0.3px"
            marginTop={64}
            textTransform="uppercase"
          >
            Likes
          </Box>
          <Grid
            marginTop={24}
            gridTemplateColumns="1fr 1fr 1fr 1fr"
            gridTemplateRows="44px"
            gridRowGap={16}
            gridColumnGap={32}
          >
            <AnimatePresence initial={false}>
              {interestsContext.like.map((interest) => (
                <motion.div
                  key={interest}
                  layout
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                >
                  <CategoryCard
                    key={interest}
                    name={interest}
                    onRemove={() => {
                      interestsContext.removeInterestLike(interest);
                      event({
                        category: "LikedInterests",
                        action: "removed_interest",
                      });
                    }}
                  />
                </motion.div>
              ))}
            </AnimatePresence>
          </Grid>
          <Row
            maxWidth={200}
            marginTop={24}
            alignItems="center"
            cursor="pointer"
            paddingV={2}
            paddingLeft={4}
            paddingRight={4}
            borderRadius={20}
            backgroundColor="#1B2025"
            hoverBackgroundColor="rgba(0,211,152,0.1)"
            transitionDuration="300ms"
            props={{
              onClick: () => {
                setOpenAddCategoriesLike(true);
                event({
                  category: "LikedInterests",
                  action: "clicked_add_more_categories",
                });
              },
            }}
          >
            <Box
              component="img"
              props={{ src: require("./images/icon-add.svg") }}
              width={20}
              height={20}
              marginRight={8}
            />
            <Box
              fontFamily="Avenir Next"
              fontWeight={600}
              fontSize={13}
              marginTop={2}
              letterSpacing="0.4px"
              textTransform="uppercase"
              color="#00D398"
            >
              Add more categories
            </Box>
          </Row>
          <Box
            fontFamily="Montserrat"
            fontWeight={600}
            fontSize={18}
            color="#FFFFFF"
            letterSpacing="0.3px"
            marginTop={64}
            textTransform="uppercase"
          >
            Dislikes
          </Box>
          <Grid
            marginTop={24}
            gridTemplateColumns="1fr 1fr 1fr 1fr"
            gridTemplateRows="44px"
            gridRowGap={16}
            gridColumnGap={32}
          >
            <AnimatePresence initial={false}>
              {interestsContext.dislike.map((interest) => (
                <motion.div
                  key={interest}
                  layout
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                >
                  <CategoryCard
                    key={interest}
                    name={interest}
                    onRemove={() => {
                      interestsContext.removeInterestDislike(interest);
                      event({
                        category: "DislikedInterests",
                        action: "removed_interest",
                      });
                    }}
                  />
                </motion.div>
              ))}
            </AnimatePresence>
          </Grid>
          <Row
            maxWidth={200}
            marginTop={24}
            alignItems="center"
            cursor="pointer"
            paddingV={2}
            paddingLeft={4}
            paddingRight={4}
            borderRadius={20}
            backgroundColor="#1B2025"
            hoverBackgroundColor="rgba(0,211,152,0.1)"
            transitionDuration="300ms"
            props={{
              onClick: () => {
                setOpenAddCategoriesDislike(true);
                event({
                  category: "DislikedInterests",
                  action: "clicked_add_more_categories",
                });
              },
            }}
          >
            <Box
              component="img"
              props={{ src: require("./images/icon-add.svg") }}
              width={20}
              height={20}
              marginRight={8}
            />
            <Box
              fontFamily="Avenir Next"
              fontWeight={600}
              fontSize={13}
              marginTop={2}
              letterSpacing="0.4px"
              textTransform="uppercase"
              color="#00D398"
            >
              Add more categories
            </Box>
          </Row>
        </Col>
      </Col>
      <AddCategoriesDialog
        open={openAddCategoriesLike}
        onClose={() => setOpenAddCategoriesLike(false)}
        message="Search for categories you like to complete your profile and improve your earnings."
        categories={basicInterests.filter(
          (c) =>
            !interestsContext.like.includes(c) &&
            !interestsContext.dislike.includes(c)
        )}
        onSubmit={interestsContext.addMultipleLike}
      />
      <AddCategoriesDialog
        open={openAddCategoriesDislike}
        onClose={() => setOpenAddCategoriesDislike(false)}
        message="Search for categories you dislike to complete your profile and improve your earnings."
        categories={basicInterests.filter(
          (c) =>
            !interestsContext.like.includes(c) &&
            !interestsContext.dislike.includes(c)
        )}
        onSubmit={interestsContext.addMultipleDislike}
      />
    </>
  );
};

const basicInterests = [
  "Celebrities & Entertainment News",
  "Comics & Animation",
  "Entertainment Industry",
  "Events & Listings",
  "Fun & Trivia",
  "Humor",
  "Movies",
  "Music & Audio",
  "Offbeat",
  "Online Media",
  "Performing Arts",
  "TV & Video",
  "Visual Art & Design",
  "Bicycles & Accessories",
  "Boats & Watercraft",
  "Campers & RVs",
  "Classic Vehicles",
  "Commercial Vehicles",
  "Motor Vehicles (By Type)",
  "Vehicle Codes & Driving Laws",
  "Vehicle Parts & Services",
  "Vehicle Shopping",
  "Vehicle Shows",
  "Beauty Pageants",
  "Body Art",
  "Cosmetic Procedures",
  "Cosmetology & Beauty Professionals",
  "Face & Body Care",
  "Fashion & Style",
  "Fitness",
  "Hair Care",
  "Spas & Beauty Services",
  "Weight Loss",
  "Children's Literature",
  "E-Books",
  "Fan Fiction",
  "Literary Classics",
  "Poetry",
  "Writers Resources",
  "Advertising & Marketing",
  "Aerospace & Defense",
  "Agriculture & Forestry",
  "Automotive Industry",
  "Business Education",
  "Business Finance",
  "Business Operations",
  "Business Services",
  "Chemicals Industry",
  "Construction & Maintenance",
  "Energy & Utilities",
  "Hospitality Industry",
  "Industrial Materials & Equipment",
  "Manufacturing",
  "Metals & Mining",
  "Pharmaceuticals & Biotech",
  "Printing & Publishing",
  "Retail Trade",
  "Small Business",
  "Textiles & Nonwovens",
  "Transportation & Logistics",
  "CAD & CAM",
  "Computer Hardware",
  "Computer Security",
  "Consumer Electronics",
  "Electronics & Electrical",
  "Enterprise Technology",
  "Networking",
  "Programming",
  "Software",
  "Accounting & Auditing",
  "Banking",
  "Credit & Lending",
  "Financial Planning & Management",
  "Grants, Scholarships & Financial Aid",
  "Insurance",
  "Investing",
  "Beverages",
  "Cooking & Recipes",
  "Food",
  "Food & Grocery Retailers",
  "Restaurants",
  "Arcade & Coin-Op Games",
  "Board Games",
  "Card Games",
  "Computer & Video Games",
  "Family-Oriented Games & Activities",
  "Gambling",
  "Online Games",
  "Puzzles & Brainteasers",
  "Roleplaying Games",
  "Table Games",
  "Word Games",
  "Aging & Geriatrics",
  "Health Conditions",
  "Health Education & Medical Training",
  "Health Foundations & Medical Research",
  "Medical Devices & Equipment",
  "Medical Facilities & Services",
  "Men's Health",
  "Mental Health",
  "Nursing",
  "Nutrition",
  "Oral & Dental Care",
  "Pharmacy",
  "Public Health",
  "Reproductive Health",
  "Substance Abuse",
  "Vision Care",
  "Women's Health",
  "Clubs & Organizations",
  "Crafts",
  "Merit Prizes & Contests",
  "Outdoors",
  "Paintball",
  "Radio Control & Modeling",
  "Special Occasions",
  "Water Activities",
  "Bed & Bath",
  "Domestic Services",
  "Gardening & Landscaping",
  "Home & Interior Decor",
  "Home Appliances",
  "Home Furnishings",
  "Home Improvement",
  "Home Safety & Security",
  "Home Storage & Shelving",
  "Home Swimming Pools, Saunas & Spas",
  "HVAC & Climate Control",
  "Kitchen & Dining",
  "Laundry",
  "Nursery & Playroom",
  "Pest Control",
  "Yard & Patio",
  "Communications Equipment",
  "Email & Messaging",
  "Mobile & Wireless",
  "Service Providers",
  "Web Services",
  "Education",
  "Jobs",
  "Government",
  "Legal",
  "Military",
  "Public Safety",
  "Social Services",
  "Business News",
  "Gossip & Tabloid News",
  "Health News",
  "Politics",
  "Sports News",
  "Weather",
  "Blogging Resources & Services",
  "Dating & Personals",
  "File Sharing & Hosting",
  "Online Goodies",
  "Photo & Video Sharing",
  "Social Networks",
  "Virtual Worlds",
  "Family & Relationships",
  "Kids & Teens",
  "Religion & Belief",
  "Seniors & Retirement",
  "Social Issues & Advocacy",
  "Social Sciences",
  "Subcultures & Niche Interests",
  "Animal Products & Services",
  "Pets",
  "Wildlife",
  "Real Estate Listings",
  "Real Estate Services",
  "Directories & Listings",
  "General Reference",
  "Geographic Reference",
  "Humanities",
  "Language Resources",
  "Libraries & Museums",
  "Astronomy",
  "Biological Sciences",
  "Chemistry",
  "Computer Science",
  "Earth Sciences",
  "Ecology & Environment",
  "Engineering & Technology",
  "Mathematics",
  "Physics",
  "Scientific Institutions",
  "Antiques & Collectibles",
  "Apparel",
  "Auctions",
  "Classifieds",
  "Consumer Resources",
  "Entertainment Media",
  "Gifts & Special Event Items",
  "Luxury Goods",
  "Mass Merchants & Department Stores",
  "Photo & Video Services",
  "Tobacco Products",
  "Toys",
  "Animal Sports",
  "College Sports",
  "Combat Sports",
  "Extreme Sports",
  "Fantasy Sports",
  "Individual Sports",
  "International Sports Competitions",
  "Motor Sports",
  "Sporting Goods",
  "Sports Coaching & Training",
  "Team Sports",
  "Water Sports",
  "Winter Sports",
  "Air Travel",
  "Bus & Rail",
  "Car Rental & Taxi Services",
  "Cruises & Charters",
  "Hotels & Accommodations",
  "Specialty Travel",
  "Tourist Destinations",
];

const cities: Record<string, Array<string>> = {
  "New York": [
    "New York",
    "Buffalo",
    "Rochester",
    "Yonkers",
    "Syracuse",
    "Albany",
    "New Rochelle",
    "Mount Vernon",
    "Schenectady",
    "Utica",
    "White Plains",
    "Hempstead",
    "Troy",
    "Niagara Falls",
    "Binghamton",
    "Freeport",
    "Valley Stream",
  ],
  California: [
    "Los Angeles",
    "San Diego",
    "San Jose",
    "San Francisco",
    "Fresno",
    "Sacramento",
    "Long Beach",
    "Oakland",
    "Bakersfield",
    "Anaheim",
    "Santa Ana",
    "Riverside",
    "Stockton",
    "Chula Vista",
    "Irvine",
    "Fremont",
    "San Bernardino",
    "Modesto",
    "Fontana",
    "Oxnard",
    "Moreno Valley",
    "Huntington Beach",
    "Glendale",
    "Santa Clarita",
    "Garden Grove",
    "Oceanside",
    "Rancho Cucamonga",
    "Santa Rosa",
    "Ontario",
    "Lancaster",
    "Elk Grove",
    "Corona",
    "Palmdale",
    "Salinas",
    "Pomona",
    "Hayward",
    "Escondido",
    "Torrance",
    "Sunnyvale",
    "Orange",
    "Fullerton",
    "Pasadena",
    "Thousand Oaks",
    "Visalia",
    "Simi Valley",
    "Concord",
    "Roseville",
    "Victorville",
    "Santa Clara",
    "Vallejo",
    "Berkeley",
    "El Monte",
    "Downey",
    "Costa Mesa",
    "Inglewood",
    "Carlsbad",
    "San Buenaventura (Ventura)",
    "Fairfield",
    "West Covina",
    "Murrieta",
    "Richmond",
    "Norwalk",
    "Antioch",
    "Temecula",
    "Burbank",
    "Daly City",
    "Rialto",
    "Santa Maria",
    "El Cajon",
    "San Mateo",
    "Clovis",
    "Compton",
    "Jurupa Valley",
    "Vista",
    "South Gate",
    "Mission Viejo",
    "Vacaville",
    "Carson",
    "Hesperia",
    "Santa Monica",
    "Westminster",
    "Redding",
    "Santa Barbara",
    "Chico",
    "Newport Beach",
    "San Leandro",
    "San Marcos",
    "Whittier",
    "Hawthorne",
    "Citrus Heights",
    "Tracy",
    "Alhambra",
    "Livermore",
    "Buena Park",
    "Menifee",
    "Hemet",
    "Lakewood",
    "Merced",
    "Chino",
    "Indio",
    "Redwood City",
    "Lake Forest",
    "Napa",
    "Tustin",
    "Bellflower",
    "Mountain View",
    "Chino Hills",
    "Baldwin Park",
    "Alameda",
    "Upland",
    "San Ramon",
    "Folsom",
    "Pleasanton",
    "Union City",
    "Perris",
    "Manteca",
    "Lynwood",
    "Apple Valley",
    "Redlands",
    "Turlock",
    "Milpitas",
    "Redondo Beach",
    "Rancho Cordova",
    "Yorba Linda",
    "Palo Alto",
    "Davis",
    "Camarillo",
    "Walnut Creek",
    "Pittsburg",
    "South San Francisco",
    "Yuba City",
    "San Clemente",
    "Laguna Niguel",
    "Pico Rivera",
    "Montebello",
    "Lodi",
    "Madera",
    "Santa Cruz",
    "La Habra",
    "Encinitas",
    "Monterey Park",
    "Tulare",
    "Cupertino",
    "Gardena",
    "National City",
    "Rocklin",
    "Petaluma",
    "Huntington Park",
    "San Rafael",
    "La Mesa",
    "Arcadia",
    "Fountain Valley",
    "Diamond Bar",
    "Woodland",
    "Santee",
    "Lake Elsinore",
    "Porterville",
    "Paramount",
    "Eastvale",
    "Rosemead",
    "Hanford",
    "Highland",
    "Brentwood",
    "Novato",
    "Colton",
    "Cathedral City",
    "Delano",
    "Yucaipa",
    "Watsonville",
    "Placentia",
    "Glendora",
    "Gilroy",
    "Palm Desert",
    "Cerritos",
    "West Sacramento",
    "Aliso Viejo",
    "Poway",
    "La Mirada",
    "Rancho Santa Margarita",
    "Cypress",
    "Dublin",
    "Covina",
    "Azusa",
    "Palm Springs",
    "San Luis Obispo",
    "Ceres",
    "San Jacinto",
    "Lincoln",
    "Newark",
    "Lompoc",
    "El Centro",
    "Danville",
    "Bell Gardens",
    "Coachella",
    "Rancho Palos Verdes",
    "San Bruno",
    "Rohnert Park",
    "Brea",
    "La Puente",
    "Campbell",
    "San Gabriel",
    "Beaumont",
    "Morgan Hill",
    "Culver City",
    "Calexico",
    "Stanton",
    "La Quinta",
    "Pacifica",
    "Montclair",
    "Oakley",
    "Monrovia",
    "Los Banos",
    "Martinez",
  ],
  Illinois: [
    "Chicago",
    "Aurora",
    "Rockford",
    "Joliet",
    "Naperville",
    "Springfield",
    "Peoria",
    "Elgin",
    "Waukegan",
    "Cicero",
    "Champaign",
    "Bloomington",
    "Arlington Heights",
    "Evanston",
    "Decatur",
    "Schaumburg",
    "Bolingbrook",
    "Palatine",
    "Skokie",
    "Des Plaines",
    "Orland Park",
    "Tinley Park",
    "Oak Lawn",
    "Berwyn",
    "Mount Prospect",
    "Normal",
    "Wheaton",
    "Hoffman Estates",
    "Oak Park",
    "Downers Grove",
    "Elmhurst",
    "Glenview",
    "DeKalb",
    "Lombard",
    "Belleville",
    "Moline",
    "Buffalo Grove",
    "Bartlett",
    "Urbana",
    "Quincy",
    "Crystal Lake",
    "Plainfield",
    "Streamwood",
    "Carol Stream",
    "Romeoville",
    "Rock Island",
    "Hanover Park",
    "Carpentersville",
    "Wheeling",
    "Park Ridge",
    "Addison",
    "Calumet City",
  ],
  Texas: [
    "Houston",
    "San Antonio",
    "Dallas",
    "Austin",
    "Fort Worth",
    "El Paso",
    "Arlington",
    "Corpus Christi",
    "Plano",
    "Laredo",
    "Lubbock",
    "Garland",
    "Irving",
    "Amarillo",
    "Grand Prairie",
    "Brownsville",
    "Pasadena",
    "McKinney",
    "Mesquite",
    "McAllen",
    "Killeen",
    "Frisco",
    "Waco",
    "Carrollton",
    "Denton",
    "Midland",
    "Abilene",
    "Beaumont",
    "Round Rock",
    "Odessa",
    "Wichita Falls",
    "Richardson",
    "Lewisville",
    "Tyler",
    "College Station",
    "Pearland",
    "San Angelo",
    "Allen",
    "League City",
    "Sugar Land",
    "Longview",
    "Edinburg",
    "Mission",
    "Bryan",
    "Baytown",
    "Pharr",
    "Temple",
    "Missouri City",
    "Flower Mound",
    "Harlingen",
    "North Richland Hills",
    "Victoria",
    "Conroe",
    "New Braunfels",
    "Mansfield",
    "Cedar Park",
    "Rowlett",
    "Port Arthur",
    "Euless",
    "Georgetown",
    "Pflugerville",
    "DeSoto",
    "San Marcos",
    "Grapevine",
    "Bedford",
    "Galveston",
    "Cedar Hill",
    "Texas City",
    "Wylie",
    "Haltom City",
    "Keller",
    "Coppell",
    "Rockwall",
    "Huntsville",
    "Duncanville",
    "Sherman",
    "The Colony",
    "Burleson",
    "Hurst",
    "Lancaster",
    "Texarkana",
    "Friendswood",
    "Weslaco",
  ],
  Pennsylvania: [
    "Philadelphia",
    "Pittsburgh",
    "Allentown",
    "Erie",
    "Reading",
    "Scranton",
    "Bethlehem",
    "Lancaster",
    "Harrisburg",
    "Altoona",
    "York",
    "State College",
    "Wilkes-Barre",
  ],
  Arizona: [
    "Phoenix",
    "Tucson",
    "Mesa",
    "Chandler",
    "Glendale",
    "Scottsdale",
    "Gilbert",
    "Tempe",
    "Peoria",
    "Surprise",
    "Yuma",
    "Avondale",
    "Goodyear",
    "Flagstaff",
    "Buckeye",
    "Lake Havasu City",
    "Casa Grande",
    "Sierra Vista",
    "Maricopa",
    "Oro Valley",
    "Prescott",
    "Bullhead City",
    "Prescott Valley",
    "Marana",
    "Apache Junction",
  ],
  Florida: [
    "Jacksonville",
    "Miami",
    "Tampa",
    "Orlando",
    "St. Petersburg",
    "Hialeah",
    "Tallahassee",
    "Fort Lauderdale",
    "Port St. Lucie",
    "Cape Coral",
    "Pembroke Pines",
    "Hollywood",
    "Miramar",
    "Gainesville",
    "Coral Springs",
    "Miami Gardens",
    "Clearwater",
    "Palm Bay",
    "Pompano Beach",
    "West Palm Beach",
    "Lakeland",
    "Davie",
    "Miami Beach",
    "Sunrise",
    "Plantation",
    "Boca Raton",
    "Deltona",
    "Largo",
    "Deerfield Beach",
    "Palm Coast",
    "Melbourne",
    "Boynton Beach",
    "Lauderhill",
    "Weston",
    "Fort Myers",
    "Kissimmee",
    "Homestead",
    "Tamarac",
    "Delray Beach",
    "Daytona Beach",
    "North Miami",
    "Wellington",
    "North Port",
    "Jupiter",
    "Ocala",
    "Port Orange",
    "Margate",
    "Coconut Creek",
    "Sanford",
    "Sarasota",
    "Pensacola",
    "Bradenton",
    "Palm Beach Gardens",
    "Pinellas Park",
    "Coral Gables",
    "Doral",
    "Bonita Springs",
    "Apopka",
    "Titusville",
    "North Miami Beach",
    "Oakland Park",
    "Fort Pierce",
    "North Lauderdale",
    "Cutler Bay",
    "Altamonte Springs",
    "St. Cloud",
    "Greenacres",
    "Ormond Beach",
    "Ocoee",
    "Hallandale Beach",
    "Winter Garden",
    "Aventura",
  ],
  Indiana: [
    "Indianapolis",
    "Fort Wayne",
    "Evansville",
    "South Bend",
    "Carmel",
    "Bloomington",
    "Fishers",
    "Hammond",
    "Gary",
    "Muncie",
    "Lafayette",
    "Terre Haute",
    "Kokomo",
    "Anderson",
    "Noblesville",
    "Greenwood",
    "Elkhart",
    "Mishawaka",
    "Lawrence",
    "Jeffersonville",
    "Columbus",
    "Portage",
  ],
  Ohio: [
    "Columbus",
    "Cleveland",
    "Cincinnati",
    "Toledo",
    "Akron",
    "Dayton",
    "Parma",
    "Canton",
    "Youngstown",
    "Lorain",
    "Hamilton",
    "Springfield",
    "Kettering",
    "Elyria",
    "Lakewood",
    "Cuyahoga Falls",
    "Middletown",
    "Euclid",
    "Newark",
    "Mansfield",
    "Mentor",
    "Beavercreek",
    "Cleveland Heights",
    "Strongsville",
    "Dublin",
    "Fairfield",
    "Findlay",
    "Warren",
    "Lancaster",
    "Lima",
    "Huber Heights",
    "Westerville",
    "Marion",
    "Grove City",
  ],
  "North Carolina": [
    "Charlotte",
    "Raleigh",
    "Greensboro",
    "Durham",
    "Winston-Salem",
    "Fayetteville",
    "Cary",
    "Wilmington",
    "High Point",
    "Greenville",
    "Asheville",
    "Concord",
    "Gastonia",
    "Jacksonville",
    "Chapel Hill",
    "Rocky Mount",
    "Burlington",
    "Wilson",
    "Huntersville",
    "Kannapolis",
    "Apex",
    "Hickory",
    "Goldsboro",
  ],
  Michigan: [
    "Detroit",
    "Grand Rapids",
    "Warren",
    "Sterling Heights",
    "Ann Arbor",
    "Lansing",
    "Flint",
    "Dearborn",
    "Livonia",
    "Westland",
    "Troy",
    "Farmington Hills",
    "Kalamazoo",
    "Wyoming",
    "Southfield",
    "Rochester Hills",
    "Taylor",
    "Pontiac",
    "St. Clair Shores",
    "Royal Oak",
    "Novi",
    "Dearborn Heights",
    "Battle Creek",
    "Saginaw",
    "Kentwood",
    "East Lansing",
    "Roseville",
    "Portage",
    "Midland",
    "Lincoln Park",
    "Muskegon",
  ],
  Tennessee: [
    "Memphis",
    "Nashville-Davidson",
    "Knoxville",
    "Chattanooga",
    "Clarksville",
    "Murfreesboro",
    "Jackson",
    "Franklin",
    "Johnson City",
    "Bartlett",
    "Hendersonville",
    "Kingsport",
    "Collierville",
    "Cleveland",
    "Smyrna",
    "Germantown",
    "Brentwood",
  ],
  Massachusetts: [
    "Boston",
    "Worcester",
    "Springfield",
    "Lowell",
    "Cambridge",
    "New Bedford",
    "Brockton",
    "Quincy",
    "Lynn",
    "Fall River",
    "Newton",
    "Lawrence",
    "Somerville",
    "Waltham",
    "Haverhill",
    "Malden",
    "Medford",
    "Taunton",
    "Chicopee",
    "Weymouth Town",
    "Revere",
    "Peabody",
    "Methuen",
    "Barnstable Town",
    "Pittsfield",
    "Attleboro",
    "Everett",
    "Salem",
    "Westfield",
    "Leominster",
    "Fitchburg",
    "Beverly",
    "Holyoke",
    "Marlborough",
    "Woburn",
    "Chelsea",
  ],
  Washington: [
    "Seattle",
    "Spokane",
    "Tacoma",
    "Vancouver",
    "Bellevue",
    "Kent",
    "Everett",
    "Renton",
    "Yakima",
    "Federal Way",
    "Spokane Valley",
    "Bellingham",
    "Kennewick",
    "Auburn",
    "Pasco",
    "Marysville",
    "Lakewood",
    "Redmond",
    "Shoreline",
    "Richland",
    "Kirkland",
    "Burien",
    "Sammamish",
    "Olympia",
    "Lacey",
    "Edmonds",
    "Bremerton",
    "Puyallup",
  ],
  Colorado: [
    "Denver",
    "Colorado Springs",
    "Aurora",
    "Fort Collins",
    "Lakewood",
    "Thornton",
    "Arvada",
    "Westminster",
    "Pueblo",
    "Centennial",
    "Boulder",
    "Greeley",
    "Longmont",
    "Loveland",
    "Grand Junction",
    "Broomfield",
    "Castle Rock",
    "Commerce City",
    "Parker",
    "Littleton",
    "Northglenn",
  ],
  "District of Columbia": ["Washington"],
  Maryland: [
    "Baltimore",
    "Frederick",
    "Rockville",
    "Gaithersburg",
    "Bowie",
    "Hagerstown",
    "Annapolis",
  ],
  Kentucky: [
    "Louisville/Jefferson County",
    "Lexington-Fayette",
    "Bowling Green",
    "Owensboro",
    "Covington",
  ],
  Oregon: [
    "Portland",
    "Eugene",
    "Salem",
    "Gresham",
    "Hillsboro",
    "Beaverton",
    "Bend",
    "Medford",
    "Springfield",
    "Corvallis",
    "Albany",
    "Tigard",
    "Lake Oswego",
    "Keizer",
  ],
  Oklahoma: [
    "Oklahoma City",
    "Tulsa",
    "Norman",
    "Broken Arrow",
    "Lawton",
    "Edmond",
    "Moore",
    "Midwest City",
    "Enid",
    "Stillwater",
    "Muskogee",
  ],
  Wisconsin: [
    "Milwaukee",
    "Madison",
    "Green Bay",
    "Kenosha",
    "Racine",
    "Appleton",
    "Waukesha",
    "Eau Claire",
    "Oshkosh",
    "Janesville",
    "West Allis",
    "La Crosse",
    "Sheboygan",
    "Wauwatosa",
    "Fond du Lac",
    "New Berlin",
    "Wausau",
    "Brookfield",
    "Greenfield",
    "Beloit",
  ],
  Nevada: [
    "Las Vegas",
    "Henderson",
    "Reno",
    "North Las Vegas",
    "Sparks",
    "Carson City",
  ],
  "New Mexico": [
    "Albuquerque",
    "Las Cruces",
    "Rio Rancho",
    "Santa Fe",
    "Roswell",
    "Farmington",
    "Clovis",
  ],
  Missouri: [
    "Kansas City",
    "St. Louis",
    "Springfield",
    "Independence",
    "Columbia",
    "Lee's Summit",
    "O'Fallon",
    "St. Joseph",
    "St. Charles",
    "St. Peters",
    "Blue Springs",
    "Florissant",
    "Joplin",
    "Chesterfield",
    "Jefferson City",
    "Cape Girardeau",
  ],
  Virginia: [
    "Virginia Beach",
    "Norfolk",
    "Chesapeake",
    "Richmond",
    "Newport News",
    "Alexandria",
    "Hampton",
    "Roanoke",
    "Portsmouth",
    "Suffolk",
    "Lynchburg",
    "Harrisonburg",
    "Leesburg",
    "Charlottesville",
    "Danville",
    "Blacksburg",
    "Manassas",
  ],
  Georgia: [
    "Atlanta",
    "Columbus",
    "Augusta-Richmond County",
    "Savannah",
    "Athens-Clarke County",
    "Sandy Springs",
    "Roswell",
    "Macon",
    "Johns Creek",
    "Albany",
    "Warner Robins",
    "Alpharetta",
    "Marietta",
    "Valdosta",
    "Smyrna",
    "Dunwoody",
  ],
  Nebraska: ["Omaha", "Lincoln", "Bellevue", "Grand Island"],
  Minnesota: [
    "Minneapolis",
    "St. Paul",
    "Rochester",
    "Duluth",
    "Bloomington",
    "Brooklyn Park",
    "Plymouth",
    "St. Cloud",
    "Eagan",
    "Woodbury",
    "Maple Grove",
    "Eden Prairie",
    "Coon Rapids",
    "Burnsville",
    "Blaine",
    "Lakeville",
    "Minnetonka",
    "Apple Valley",
    "Edina",
    "St. Louis Park",
    "Mankato",
    "Maplewood",
    "Moorhead",
    "Shakopee",
  ],
  Kansas: [
    "Wichita",
    "Overland Park",
    "Kansas City",
    "Olathe",
    "Topeka",
    "Lawrence",
    "Shawnee",
    "Manhattan",
    "Lenexa",
    "Salina",
    "Hutchinson",
  ],
  Louisiana: [
    "New Orleans",
    "Baton Rouge",
    "Shreveport",
    "Lafayette",
    "Lake Charles",
    "Kenner",
    "Bossier City",
    "Monroe",
    "Alexandria",
  ],
  Hawaii: ["Honolulu"],
  Alaska: ["Anchorage"],
  "New Jersey": [
    "Newark",
    "Jersey City",
    "Paterson",
    "Elizabeth",
    "Clifton",
    "Trenton",
    "Camden",
    "Passaic",
    "Union City",
    "Bayonne",
    "East Orange",
    "Vineland",
    "New Brunswick",
    "Hoboken",
    "Perth Amboy",
    "West New York",
    "Plainfield",
    "Hackensack",
    "Sayreville",
    "Kearny",
    "Linden",
    "Atlantic City",
  ],
  Idaho: [
    "Boise City",
    "Nampa",
    "Meridian",
    "Idaho Falls",
    "Pocatello",
    "Caldwell",
    "Coeur d'Alene",
    "Twin Falls",
  ],
  Alabama: [
    "Birmingham",
    "Montgomery",
    "Mobile",
    "Huntsville",
    "Tuscaloosa",
    "Hoover",
    "Dothan",
    "Auburn",
    "Decatur",
    "Madison",
    "Florence",
    "Gadsden",
  ],
  Iowa: [
    "Des Moines",
    "Cedar Rapids",
    "Davenport",
    "Sioux City",
    "Iowa City",
    "Waterloo",
    "Council Bluffs",
    "Ames",
    "West Des Moines",
    "Dubuque",
    "Ankeny",
    "Urbandale",
    "Cedar Falls",
  ],
  Arkansas: [
    "Little Rock",
    "Fort Smith",
    "Fayetteville",
    "Springdale",
    "Jonesboro",
    "North Little Rock",
    "Conway",
    "Rogers",
    "Pine Bluff",
    "Bentonville",
  ],
  Utah: [
    "Salt Lake City",
    "West Valley City",
    "Provo",
    "West Jordan",
    "Orem",
    "Sandy",
    "Ogden",
    "St. George",
    "Layton",
    "Taylorsville",
    "South Jordan",
    "Lehi",
    "Logan",
    "Murray",
    "Draper",
    "Bountiful",
    "Riverton",
    "Roy",
  ],
  "Rhode Island": [
    "Providence",
    "Warwick",
    "Cranston",
    "Pawtucket",
    "East Providence",
    "Woonsocket",
  ],
  Mississippi: [
    "Jackson",
    "Gulfport",
    "Southaven",
    "Hattiesburg",
    "Biloxi",
    "Meridian",
  ],
  "South Dakota": ["Sioux Falls", "Rapid City"],
  Connecticut: [
    "Bridgeport",
    "New Haven",
    "Stamford",
    "Hartford",
    "Waterbury",
    "Norwalk",
    "Danbury",
    "New Britain",
    "Meriden",
    "Bristol",
    "West Haven",
    "Milford",
    "Middletown",
    "Norwich",
    "Shelton",
  ],
  "South Carolina": [
    "Columbia",
    "Charleston",
    "North Charleston",
    "Mount Pleasant",
    "Rock Hill",
    "Greenville",
    "Summerville",
    "Sumter",
    "Goose Creek",
    "Hilton Head Island",
    "Florence",
    "Spartanburg",
  ],
  "New Hampshire": ["Manchester", "Nashua", "Concord"],
  "North Dakota": ["Fargo", "Bismarck", "Grand Forks", "Minot"],
  Montana: ["Billings", "Missoula", "Great Falls", "Bozeman"],
  Delaware: ["Wilmington", "Dover"],
  Maine: ["Portland"],
  Wyoming: ["Cheyenne", "Casper"],
  "West Virginia": ["Charleston", "Huntington"],
  Vermont: ["Burlington"],
};

function App() {
  const [token, setToken] = useState(
    localStorage.getItem("control_auth_token")
  );
  const [openLogin, setOpenLogin] = useState(false);
  const [openSignup, setOpenSignup] = useState(false);
  const [sellMyData, setSellMyData] = useState(true);
  const [like, setLike] = useLocalState<Array<string>>(
    [
      "Restaurants",
      "Politics",
      "Social Networks",
      "Apparel",
      "Extreme Sports",
      "Gardening & Landscaping",
    ],
    "control_liked_categories"
  );
  const [dislike, setDislike] = useLocalState<Array<string>>(
    [
      "Water Activities",
      "Home & Interior Decor",
      "Religion & Belief",
      "Antiques & Collectibles",
      "Combat Sports",
      "Pets",
    ],
    "control_disliked_categories"
  );
  const [demographicItems, setDItems] = useLocalState<Array<Demographic>>(
    [
      {
        id: uuid(),
        field: "Age",
        value: "30 - 39",
        state: "inferred",
        options: [
          "17 or younger",
          "18 - 20",
          "21 - 29",
          "30 - 39",
          "40 - 49",
          "50 - 59",
          "60+",
        ],
      },
      {
        id: uuid(),
        field: "Gender",
        value: "Female",
        state: "inferred",
        options: ["Female", "Male", "Other"],
      },
      {
        id: uuid(),
        field: "Location",
        value: "New York, NY",
        state: "inferred",
        options: Object.entries(cities)
          .map(([k, v]) => v.map((city: string) => `${k}, ${city}`))
          .flat(),
      },
      {
        id: uuid(),
        field: "Politics",
        value: "Liberal",
        state: "inferred",
        options: ["Liberal", "Moderate", "Conservative", "Other"],
      },
      {
        id: uuid(),
        field: "Education",
        value: "High school degree or equivalent",
        state: "inferred",
        options: [
          "Less than high school degree",
          "High school degree or equivalent",
          "Some college, but no degree",
          "Associate degree",
          "Bachelor degree",
          "Graduate Degree",
          "Other",
        ],
      },
      {
        id: uuid(),
        field: "Marital Status",
        value: "Never Married",
        state: "inferred",
        options: [
          "Married",
          "Widowed",
          "Divorced",
          "Separated",
          "Never Married",
        ],
      },
      {
        id: uuid(),
        field: "People in household",
        value: "2",
        state: "inferred",
        options: ["1", "2", "3", "4", "5", "6+"],
      },
      {
        id: uuid(),
        field: "Household Income",
        value: "$60,000 – $69,999",
        state: "inferred",
        options: [
          "$0 – $9,999",
          "$10,000 – $19,999",
          "$20,000 – $29,999",
          "$30,000 – $39,999",
          "$40,000 – $49,999",
          "$50,000 – $59,999",
          "$60,000 – $69,999",
          "$70,000 – $79,999",
          "$80,000 – $89,999",
          "$90,000 – $99,999",
          "$100,000+",
        ],
      },

      {
        id: uuid(),
        field: "Employment Status",
        state: "inferred",
        value: "Employed",
        options: ["Employed", "Not Employed", "Retired", "Other"],
      },
    ],
    "control_demographics"
  );

  const demographicsEarnings = demographicItems
    .map((i): number => {
      switch (i.state) {
        case "verified":
          return 4;
        case "inferred":
          return 2;
        case "unknown":
          return 0;
      }
    })
    .reduce((a, b) => a + b, 0);

  const demographicsProfileCompletion = demographicItems
    .map((i): number => {
      switch (i.state) {
        case "verified":
          return 5;
        case "inferred":
          return 2;
        case "unknown":
          return 0;
      }
    })
    .reduce((a, b) => a + b, 0);

  return (
    <AuthContext.Provider
      value={{
        token,
        setToken: (t) => {
          localStorage.setItem("control_auth_token", t);
          setToken(t);
        },
        logout: () => {
          localStorage.removeItem("control_auth_token");
          setToken(null);
        },
      }}
    >
      <ModalsContext.Provider
        value={{
          openLogin: () => setOpenLogin(true),
          displayLogin: openLogin,
          displaySignup: openSignup,
          openSignup: () => setOpenSignup(true),
        }}
      >
        <DataContext.Provider
          value={{
            wallet: 0,
            earnings: sellMyData
              ? 20 + like.length + dislike.length + demographicsEarnings
              : 0,
            profileCompletion:
              Math.min(like.length + dislike.length, 15) +
              demographicsProfileCompletion,
            sellMyData,
            toggleSellData: () => setSellMyData((o) => !o),
          }}
        >
          <DemographicContext.Provider
            value={{
              items: demographicItems,
              setDemographicState: (id, state) => {
                triggerModifyPixel();
                setDItems((is) =>
                  is.map((i) => (i.id === id ? { ...i, state } : i))
                );
              },
              setValue: (id, value) => {
                triggerModifyPixel();
                setDItems((is) =>
                  is.map((i) => (i.id === id ? { ...i, value } : i))
                );
              },
            }}
          >
            <InterestsContext.Provider
              value={{
                like,
                dislike,
                addInterestLike: (int) => {
                  triggerModifyPixel();
                  setLike([...like, int]);
                },
                addMultipleLike: (v) => {
                  triggerModifyPixel();
                  setLike([...like, ...v]);
                },
                removeInterestLike: (int) => {
                  triggerModifyPixel();
                  setLike(like.filter((i) => i !== int));
                },
                addInterestDislike: (int) => {
                  triggerModifyPixel();
                  setDislike([...dislike, int]);
                },
                addMultipleDislike: (v) => {
                  triggerModifyPixel();
                  setDislike([...dislike, ...v]);
                },
                removeInterestDislike: (int) => {
                  triggerModifyPixel();
                  setDislike(dislike.filter((i) => i !== int));
                },
              }}
            >
              <Col userSelect="none">
                <AppBar />
                <Hero />
                <Col marginBottom={48} maxWidth={1344} marginH="auto">
                  <Row marginTop="-170px">
                    <IntroCard />
                    <Box marginLeft={48} />
                    <DataSources />
                  </Row>
                  <Box marginTop={64} />
                  <MyProfileCard />
                </Col>
              </Col>
              <Footer />
              <Signup open={openSignup} onClose={() => setOpenSignup(false)} />
              <Login open={openLogin} onClose={() => setOpenLogin(false)} />
            </InterestsContext.Provider>
          </DemographicContext.Provider>
        </DataContext.Provider>
      </ModalsContext.Provider>
    </AuthContext.Provider>
  );
}

export default App;
