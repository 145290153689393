import { Environment, Network, RecordSource, Store } from "relay-runtime";

const environment = new Environment({
  network: Network.create((operation, variables) => {
    const token = localStorage.getItem("control_auth_token");
    const headers: Record<string, string> = {
      "Content-Type": "application/json",
    };
    if (token !== null) {
      headers["Authorization"] = token;
    }
    return fetch("https://controlgraph.studiobox.xyz/query", {
      method: "POST",
      headers,
      body: JSON.stringify({
        query: operation.text,
        variables,
      }),
    }).then((response) => {
      return response.json();
    });
  }),
  store: new Store(new RecordSource()),
});

export default environment;
