import React from "react";
import { Box } from "jsxstyle";

const Checkbox = (props: { checked?: boolean }) => {
  return (
    <>
      {props.checked ? (
        <Box
          component="img"
          props={{ src: require("../images/CheckboxFilled.svg") }}
          width={24}
          height={24}
        />
      ) : (
        <Box
          component="img"
          props={{ src: require("../images/Checkbox.svg") }}
          width={24}
          height={24}
        />
      )}
    </>
  );
};

export default Checkbox;
