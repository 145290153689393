import React from "react";
import styled from "@emotion/styled";
import Logo from "../images/InvisiblyLogo.svg";

const Footer = () => {
  return (
    <Container>
      <Content>
        <a
          href="https://invisibly.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          About Invisibly
        </a>
        <a
          href="https://www.invisibly.com/privacy-policy"
          target="_blank"
          rel="noopener noreferrer"
        >
          Privacy Policy
        </a>
        <a
          href="https://www.invisibly.com/tos"
          target="_blank"
          rel="noopener noreferrer"
        >
          Terms of service
        </a>
        <a
          href="https://www.invisibly.com/contact"
          target="_blank"
          rel="noopener noreferrer"
        >
          Contact us
        </a>
      </Content>
    </Container>
  );
};

export default Footer;

const Container = styled.div`
  height: 100px;
  margin-top: 64px;
  padding-left: 48px;
  padding-right: 48px;
  background-color: #14171b;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 0.3px;
  text-transform: uppercase;
  box-shadow: 0px -4px 40px rgba(0, 0, 0, 0.15);

  a {
    margin-left: 24px;
    margin-right: 24px;
    text-decoration: none;
    color: #ffffff;
    opacity: 0.7;
    transition-duration: 300ms;

    &:hover {
      opacity: 1;
    }
  }
`;

const Content = styled.div`
  max-width: 1344px;
  height: 100px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;
