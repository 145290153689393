import React, { useEffect } from "react";
import { Row } from "jsxstyle";
import { motion, AnimatePresence } from "framer-motion";
import { ReactComponent as IconClose } from "images/icon-close.svg";

const Dialog = (props: {
  open: boolean;
  onClose: () => void;
  children: React.ReactNode;
  width: number;
}) => {
  useEffect(() => {
    document.body.style.overflow = props.open ? "hidden" : "visible";
  }, [props.open]);
  return (
    <AnimatePresence>
      {props.open && (
        <motion.div
          style={{
            top: 0,
            right: 0,
            left: 0,
            bottom: 0,
            position: "fixed",
            zIndex: 4000,
          }}
        >
          <Row width="100%" height="100%" position="relative">
            <motion.div
              style={{
                backgroundColor: "black",
                width: "100%",
                height: "100%",
                position: "absolute",
              }}
              initial={{ opacity: 0 }}
              animate={{ opacity: 0.7 }}
              exit={{ opacity: 0 }}
              onClick={props.onClose}
            />
            <motion.div
              style={{
                display: "flex",
                flexDirection: "column",
                backgroundColor: "#20252C",
                boxShadow: "0px 0px 30px rgba(0,0,0,0.2)",
                borderRadius: 16,
                position: "absolute",
                x: "-50%",
                y: "-50%",
                left: "50%",
                padding: 24,
                width: props.width,
              }}
              initial={{ top: "150%" }}
              animate={{ top: "50%" }}
              exit={{ top: "150%" }}
              transition={{
                type: "spring",
                damping: 200,
                stiffness: 300,
              }}
            >
              <IconClose
                width={20}
                height={20}
                cursor="pointer"
                style={{
                  alignSelf: "flex-end",
                  marginTop: "-12px",
                  marginRight: "-12px",
                }}
                onClick={props.onClose}
              />
              {props.children}
            </motion.div>
          </Row>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default Dialog;
