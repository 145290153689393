import React, { useState, useContext, useRef, useEffect } from "react";
import styled from "@emotion/styled";
import Logo from "../images/InvisiblyLogo.svg";
import Wallet from "./Wallet";
import { Col, Row, Box } from "jsxstyle";
import { motion } from "framer-motion";
import { DataContext, AuthContext } from "contexts";
import Odometer from "./Odometer";
import Dialog from "./Dialog";
import { useGoogleLogin } from "react-google-login";
import { ReactComponent as UserIcon } from "images/User.svg";
import { ReactComponent as LockIcon } from "images/Lock.svg";
import { commitMutation, graphql } from "react-relay";
import { LoginMutation } from "./__generated__/LoginMutation.graphql";
import environment from "environment";

interface Props {
  open: boolean;
  onClose: () => void;
}

const Login = (props: Props) => {
  const authContext = useContext(AuthContext);

  const inputEmailRef = useRef<HTMLInputElement>(null);
  const inputPasswordRef = useRef<HTMLInputElement>(null);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const [loading, setLoading] = useState(false);

  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    if (props.open === true) {
      inputEmailRef.current?.focus();
    }
  }, [props.open]);

  const googleLogin = useGoogleLogin({
    clientId:
      "167780110278-59nh1d1d9c919ps15tauauh152dvgdlj.apps.googleusercontent.com",
    onSuccess: (res) => console.log(res),
  });

  const onLogin = () => {
    if (loading === false) {
      setLoading(true);
      if (email.trim() === "") {
        setError("Email must not be empty");
        inputEmailRef.current?.focus();
        return;
      }
      if (password.trim() === "") {
        setError("Password must not be empty");
        inputPasswordRef.current?.focus();
        return;
      }
      commitMutation<LoginMutation>(environment, {
        mutation: graphql`
          mutation LoginMutation($email: String!, $password: String!) {
            login(email: $email, password: $password)
          }
        `,
        variables: {
          email,
          password,
        },
        onError: (err) => {
          console.log("there was an error");
          console.log(err.message);
          setLoading(false);
        },
        onCompleted: (res, errors) => {
          setLoading(false);
          if (errors) {
            setError(errors[0].message);
          } else {
            if (res.login) {
              props.onClose();
              authContext.setToken(res.login);
            }
          }
        },
      });
    }
  };

  return (
    <Dialog width={800} open={props.open} onClose={props.onClose}>
      <Row flex={1}>
        <Box
          component="img"
          props={{ src: require("../images/loginImage.png") }}
        />
        <SignUpContent>
          <h6>Login</h6>
          <Row
            width="calc(100% - 32px)"
            height={44}
            borderRadius={10}
            backgroundColor="#323843"
            alignItems="center"
            paddingLeft={16}
            marginTop={8}
          >
            <UserIcon width={24} height={24} />
            <Box
              width="calc(100% - 32px - 24px - 8px)"
              marginLeft={16}
              component="input"
              backgroundColor="transparent"
              outline="none"
              textShadow="none"
              border="none"
              color="#FFFFFF"
              fontSize={15}
              props={{
                placeholder: "Email",
                type: "email",
                ref: inputEmailRef,
                value: email,
                onKeyDown: (e: React.KeyboardEvent<HTMLInputElement>) => {
                  if (e.keyCode === 13) {
                    inputPasswordRef.current?.focus();
                  }
                },
                onChange: (e: React.ChangeEvent<HTMLInputElement>) =>
                  setEmail(e.target.value),
              }}
            />
          </Row>
          <Row
            width="calc(100% - 32px)"
            height={44}
            borderRadius={10}
            backgroundColor="#323843"
            alignItems="center"
            paddingLeft={16}
            marginTop={16}
          >
            <LockIcon width={24} height={24} />
            <Box
              width="calc(100% - 32px - 24px - 8px)"
              marginLeft={16}
              component="input"
              backgroundColor="transparent"
              outline="none"
              textShadow="none"
              border="none"
              color="#FFFFFF"
              fontSize={15}
              props={{
                placeholder: "Password",
                type: "password",
                ref: inputPasswordRef,
                value: password,
                onKeyDown: (e: React.KeyboardEvent<HTMLInputElement>) => {
                  if (e.keyCode === 13) {
                    onLogin();
                  }
                },
                onChange: (e: React.ChangeEvent<HTMLInputElement>) =>
                  setPassword(e.target.value),
              }}
            />
          </Row>

          <Row
            width="100%"
            height={40}
            backgroundColor="#20252C"
            hoverBackgroundColor="#1B2025"
            transitionDuration="300ms"
            borderRadius={10}
            border="2px solid #00D398"
            justifyContent="center"
            alignItems="center"
            cursor="pointer"
            marginTop={48}
            props={{ onClick: onLogin }}
          >
            <Box
              fontFamily="Open Sans"
              fontWeight={600}
              fontSize={14}
              letterSpacing="0.2px"
              color="#00D398"
              textTransform="uppercase"
            >
              {loading ? "Loading..." : "Login"}
            </Box>
          </Row>
          {error !== null && (
            <Box
              marginTop={16}
              fontFamily="Open Sans"
              fontWeight={600}
              fontSize={14}
              textAlign="center"
              color="#F24971"
            >
              {error}
            </Box>
          )}
        </SignUpContent>
      </Row>
    </Dialog>
  );
};

const SignUpContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: calc(400px - 64px);
  padding: 48px 32px;

  h6 {
    font-family: "Montserrat", sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    color: #ffffff;
    margin-top: 0px;
    margin-bottom: 24px;
  }
  span {
    font-family: "Open Sans", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 22px;
    text-align: center;
    color: #d6dae1;
    margin-bottom: 48px;
  }
`;

export default Login;
