export const triggerSignupPixel = () => {
  // @ts-ignore
  window.dotq = window.dotq || [];
  // @ts-ignore
  window.dotq.push({
    projectId: "10000",
    properties: {
      pixelId: "10106295",
      qstrings: {
        et: "custom",
        ea: "Signup",
      },
    },
  });
};

export const triggerModifyPixel = () => {
  // @ts-ignore
  window.dotq = window.dotq || [];
  // @ts-ignore
  window.dotq.push({
    projectId: "10000",
    properties: {
      pixelId: "10106295",
      qstrings: {
        et: "custom",
        ea: "Modify",
      },
    },
  });
};

export const triggerLinkDataPixel = () => {
  // @ts-ignore
  window.dotq = window.dotq || [];
  // @ts-ignore
  window.dotq.push({
    projectId: "10000",
    properties: {
      pixelId: "10106295",
      qstrings: {
        et: "custom",
        ea: "LinkData",
      },
    },
  });
};
