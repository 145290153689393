import React from "react";
import { Row } from "jsxstyle";
import { motion } from "framer-motion";

interface Props {
  checked?: boolean;
  onClick?: () => void;
}

const Switch = (props: Props) => {
  return (
    <Row
      width={24}
      height={14}
      border={props.checked ? "2px solid #F1F1F1" : "2px solid #B2B9C3"}
      borderRadius={10}
      alignItems="center"
      justifyContent={props.checked ? "flex-end" : "flex-start"}
    >
      {props.checked ? (
        <motion.div
          style={{
            height: 8,
            width: 8,
            backgroundColor: "#00D398",
            borderRadius: 20,
            marginLeft: 4,
            marginRight: 4,
          }}
          layout
          transition={{
            type: "spring",
            stiffness: 700,
            damping: 30,
          }}
        />
      ) : (
        <motion.div
          style={{
            height: 8,
            width: 8,
            backgroundColor: "#B2B9C3",
            borderRadius: 20,
            marginLeft: 4,
            marginRight: 4,
          }}
          layout
          transition={{
            type: "spring",
            stiffness: 700,
            damping: 30,
          }}
        />
      )}
    </Row>
  );
};

export default Switch;
