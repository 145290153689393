import React, { useState, useEffect } from "react";

export function useLocalState<T>(
  def: T,
  key: string
): [T, React.Dispatch<React.SetStateAction<T>>] {
  const localState = localStorage.getItem(key);
  const defaultState = localState !== null ? JSON.parse(localState) : def;
  const [state, setState] = useState(defaultState);

  useEffect(() => {
    localStorage.setItem(key, JSON.stringify(state));
  }, [state, key]);

  return [state, setState];
}
