import React, { useContext, useState, useRef } from "react";
import styled from "@emotion/styled";
import Logo from "../images/InvisiblyLogo.svg";
import Wallet from "./Wallet";
import { Col, Row, Box } from "jsxstyle";
import { motion } from "framer-motion";
import { DataContext, ModalsContext, AuthContext } from "contexts";
import Odometer from "./Odometer";
import { ReactComponent as UserCircleIcon } from "images/UserCircle.svg";
import Popper from "@material-ui/core/Popper";
import Fade from "@material-ui/core/Fade";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";

const AppBar = () => {
  const dataContext = useContext(DataContext);
  const modalsContext = useContext(ModalsContext);
  const authContext = useContext(AuthContext);

  const profileRef = useRef<SVGSVGElement>(null);
  const [openProfileDropdown, setOpenProfileDropdown] = useState(false);

  return (
    <>
      <Container>
        <a
          href="https://invisibly.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={Logo} alt="Invisibly Logo" style={{ maxWidth: 160 }} />
        </a>
        <Row alignItems="center">
          <Col marginRight={48}>
            <Row
              fontFamily="Open Sans"
              fontSize={14}
              color="#F1F3F5"
              marginTop={0}
              alignSelf="flex-end"
            >
              Profile completion:
              <Box width={8} />
              <Odometer
                value={`${dataContext.profileCompletion}`}
                height={18}
                color={
                  dataContext.profileCompletion < 40 ? "#F24971" : "#00D398"
                }
              />
              <Box width={2} />
              {dataContext.profileCompletion < 40 ? (
                <b style={{ color: "#F24971", marginTop: 1 }}>%</b>
              ) : (
                <b style={{ color: "#00D398", marginTop: 1 }}>%</b>
              )}
            </Row>
            <Row
              height={10}
              backgroundColor="#323843"
              borderRadius={5}
              overflow="hidden"
              marginTop={8}
            >
              <motion.div
                initial={{
                  width: `0%`,
                }}
                animate={{
                  width: `${dataContext.profileCompletion}%`,
                }}
                style={{
                  backgroundColor: `${
                    dataContext.profileCompletion < 40 ? "#F24971" : "#00D398"
                  }`,
                  borderRadius: 5,
                }}
                transition={{
                  type: "spring",
                  stiffness: 30,
                  damping: 30,
                }}
              />
            </Row>
          </Col>
          <Wallet />
          {authContext.token !== null ? (
            <ClickAwayListener
              onClickAway={() => setOpenProfileDropdown(false)}
            >
              <div>
                <UserCircleIcon
                  width={44}
                  height={44}
                  style={{
                    marginLeft: 48,
                  }}
                  cursor="pointer"
                  ref={profileRef}
                  onClick={() => setOpenProfileDropdown((o) => !o)}
                />
                <Popper
                  open={openProfileDropdown}
                  anchorEl={profileRef.current}
                  placement="bottom-end"
                  style={{
                    zIndex: 1000,
                  }}
                  transition
                >
                  {({ TransitionProps }) => (
                    <Fade {...TransitionProps} timeout={350}>
                      <Row
                        width={220}
                        height={44}
                        backgroundColor="#323843"
                        borderRadius={10}
                        alignItems="center"
                        marginTop={16}
                        cursor="pointer"
                        props={{
                          onClick: () => {
                            authContext.logout();
                            setOpenProfileDropdown(false);
                          },
                        }}
                      >
                        <Box
                          fontFamily="Montserrat"
                          fontSize={14}
                          color="#FFF"
                          marginLeft={24}
                        >
                          Sign Out
                        </Box>
                      </Row>
                    </Fade>
                  )}
                </Popper>
              </div>
            </ClickAwayListener>
          ) : (
            <>
              <Row
                width={120}
                height={40}
                marginLeft={48}
                backgroundColor="#1B2025"
                hoverBackgroundColor="#20252C"
                transitionDuration="300ms"
                borderRadius={10}
                border="2px solid #00D398"
                justifyContent="center"
                alignItems="center"
                cursor="pointer"
                props={{
                  onClick: modalsContext.openLogin,
                }}
              >
                <Box
                  fontFamily="Open Sans"
                  fontWeight={600}
                  marginBottom={2}
                  fontSize={14}
                  letterSpacing="0.2px"
                  color="#00D398"
                  textTransform="uppercase"
                >
                  Login
                </Box>
              </Row>
              <Row
                width={120}
                height={40}
                marginLeft={24}
                backgroundColor="#00D398"
                hoverBackgroundColor="#00E1A2"
                transitionDuration="300ms"
                borderRadius={10}
                justifyContent="center"
                alignItems="center"
                cursor="pointer"
                props={{
                  onClick: modalsContext.openSignup,
                }}
              >
                <Box
                  fontFamily="Open Sans"
                  fontWeight={600}
                  marginBottom={2}
                  fontSize={14}
                  letterSpacing="0.2px"
                  color="#14171B"
                  textTransform="uppercase"
                >
                  Sign Up
                </Box>
              </Row>
            </>
          )}
        </Row>
      </Container>
    </>
  );
};

export default AppBar;

const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 70px;
  padding-left: 64px;
  padding-right: 64px;
  background-color: #14171b;
  box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.15);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  z-index: 200;
`;
