import React from "react";
import styled from "@emotion/styled";

const IntroCard = () => {
  return (
    <Container>
      <Section>
        <SectionTitle>User bill of rights</SectionTitle>
        <SectionContent>
          <Decorator />
          <TextGroup>
            <Paragraph>We believe that you have the right to:</Paragraph>
            <SectionSubtitle style={{ lineHeight: "40px" }}>
              1. Own your data.
              <br />
              2. Know what data is collected on you.
              <br />
              3. Know how your data is used.
              <br />
              4. Be paid for your data.
              <br />
              5. Control who advertises you.
              <br />
              6. Delete your data.
            </SectionSubtitle>
          </TextGroup>
        </SectionContent>
      </Section>
      <Section>
        <SectionTitle>How it works</SectionTitle>
        <SectionContent>
          <Decorator />
          <TextGroup>
            <SectionSubtitle>1. Verify and add to your data</SectionSubtitle>
            <Paragraph>
              The more fields you complete, the more potential to earn.
            </Paragraph>
            <SectionSubtitle>2. We advocate on your behalf</SectionSubtitle>
            <Paragraph>
              We tell advertisers not to target you on things you don’t like.
            </Paragraph>
            <SectionSubtitle>3. We serve as your data broker</SectionSubtitle>
            <Paragraph>
              We tell advertisers they may target you on things you do like, as
              long as they pay you.
            </Paragraph>
            <SectionSubtitle>4. Get paid</SectionSubtitle>
            <Paragraph>
              When advertisers use your information to send you messages, we
              take a 5% platform fee, and pass the rest to you.
            </Paragraph>
          </TextGroup>
        </SectionContent>
      </Section>
    </Container>
  );
};

export default IntroCard;

const Container = styled.div`
  flex: 1;
  padding-left: 32px;
  padding-right: 24px;
  padding-top: 48px;
  padding-bottom: 48px;
  background: #14171b;
  border-radius: 16px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
`;
const Section = styled.div`
  max-width: 365px;
  margin-left: 20px;
  margin-right: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`;
const SectionTitle = styled.span`
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: 0.3px;
  color: #00d398;
  text-transform: uppercase;
  margin-bottom: 16px;
`;
const SectionContent = styled.div`
  display: flex;
  align-items: stretch;
`;
const Decorator = styled.div`
  width: 2px;
  min-width: 2px;
  background-color: #00d398;
  margin-right: 16px;
`;
const TextGroup = styled.div`
  display: flex;
  flex-direction: column;
  font-family: "Open Sans", sans-serif;
`;
const SectionSubtitle = styled.span`
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #ffffff;
  margin-top: 16px;
`;
const Paragraph = styled.span`
  font-weight: 400;
  font-size: 15px;
  line-height: 22px;
  color: #d6dae1;
  margin-top: 6px;
  margin-bottom: 8px;
`;
