import React, { useState, useContext, useRef, useEffect } from "react";
import styled from "@emotion/styled";
import Logo from "../images/InvisiblyLogo.svg";
import Wallet from "./Wallet";
import { Col, Row, Box } from "jsxstyle";
import { motion } from "framer-motion";
import { DataContext, AuthContext } from "contexts";
import Odometer from "./Odometer";
import Dialog from "./Dialog";
import { useGoogleLogin } from "react-google-login";
import { ReactComponent as UserIcon } from "images/User.svg";
import { ReactComponent as LockIcon } from "images/Lock.svg";
import { commitMutation, graphql } from "react-relay";
import environment from "environment";
import { SignupMutation } from "./__generated__/SignupMutation.graphql";
import { triggerSignupPixel } from "pixels";

interface Props {
  open: boolean;
  onClose: () => void;
}

const Signup = (props: Props) => {
  const inputEmailRef = useRef<HTMLInputElement>(null);
  const inputPasswordRef = useRef<HTMLInputElement>(null);
  const inputConfirmPasswordRef = useRef<HTMLInputElement>(null);

  const authContext = useContext(AuthContext);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const [createAccount, setCreateAccount] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const googleLogin = useGoogleLogin({
    clientId:
      "167780110278-59nh1d1d9c919ps15tauauh152dvgdlj.apps.googleusercontent.com",
    onSuccess: (res) => console.log(res),
  });

  useEffect(() => {
    if (props.open === true) {
      inputEmailRef.current?.focus();
    } else {
      setCreateAccount(false);
      setEmail("");
      setPassword("");
      setConfirmPassword("");
      setError(null);
    }
  }, [props.open]);

  useEffect(() => {
    if (createAccount) {
      inputEmailRef.current?.focus();
    }
  }, [createAccount]);

  const onRegister = () => {
    if (email.trim() === "") {
      setError("Email must not be empty");
      inputEmailRef.current?.focus();
      return;
    }
    if (password.trim() === "") {
      setError("Password must not be empty");
      inputPasswordRef.current?.focus();
      return;
    }
    if (password !== confirmPassword) {
      setError("Passwords must match");
      inputConfirmPasswordRef.current?.focus();
      return;
    }
    commitMutation<SignupMutation>(environment, {
      mutation: graphql`
        mutation SignupMutation($email: String!, $password: String!) {
          register(email: $email, password: $password)
        }
      `,
      variables: {
        email,
        password,
      },
      onError: (err) => {
        console.log("there was an error");
        console.log(err.message);
      },
      onCompleted: (res, errors) => {
        if (errors) {
          setError(errors[0].message);
        } else {
          if (res.register) {
            props.onClose();
            triggerSignupPixel();
            authContext.setToken(res.register);
          }
        }
      },
    });
  };

  return (
    <Dialog width={800} open={props.open} onClose={props.onClose}>
      <Row flex={1}>
        <Box
          component="img"
          props={{ src: require("../images/loginImage.png") }}
        />
        {createAccount ? (
          <SignUpContent>
            <h6>Create an Account</h6>
            <Row
              width="calc(100% - 32px)"
              height={44}
              borderRadius={10}
              backgroundColor="#323843"
              alignItems="center"
              paddingLeft={16}
              marginTop={8}
            >
              <UserIcon width={24} height={24} />
              <Box
                width="calc(100% - 32px - 24px - 8px)"
                marginLeft={16}
                component="input"
                backgroundColor="transparent"
                outline="none"
                textShadow="none"
                border="none"
                color="#FFFFFF"
                fontSize={15}
                props={{
                  placeholder: "Email",
                  type: "email",
                  ref: inputEmailRef,
                  value: email,
                  onKeyDown: (e: React.KeyboardEvent<HTMLInputElement>) => {
                    if (e.keyCode === 13) {
                      inputPasswordRef.current?.focus();
                    }
                  },
                  onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                    setEmail(e.target.value);
                  },
                }}
              />
            </Row>
            <Row
              width="calc(100% - 32px)"
              height={44}
              borderRadius={10}
              backgroundColor="#323843"
              alignItems="center"
              paddingLeft={16}
              marginTop={16}
            >
              <LockIcon width={24} height={24} />
              <Box
                width="calc(100% - 32px - 24px - 8px)"
                marginLeft={16}
                component="input"
                backgroundColor="transparent"
                outline="none"
                textShadow="none"
                border="none"
                color="#FFFFFF"
                fontSize={15}
                props={{
                  placeholder: "Password",
                  type: "password",
                  ref: inputPasswordRef,
                  value: password,
                  onKeyDown: (e: React.KeyboardEvent<HTMLInputElement>) => {
                    if (e.keyCode === 13) {
                      inputConfirmPasswordRef.current?.focus();
                    }
                  },
                  onChange: (e: React.ChangeEvent<HTMLInputElement>) =>
                    setPassword(e.target.value),
                }}
              />
            </Row>
            <Row
              width="calc(100% - 32px)"
              height={44}
              borderRadius={10}
              backgroundColor="#323843"
              alignItems="center"
              paddingLeft={16}
              marginTop={16}
              marginBottom={7}
            >
              <LockIcon width={24} height={24} />
              <Box
                width="calc(100% - 64px)"
                marginLeft={16}
                component="input"
                backgroundColor="transparent"
                outline="none"
                textShadow="none"
                border="none"
                color="#FFFFFF"
                fontSize={15}
                props={{
                  placeholder: "Repeat password",
                  type: "password",
                  ref: inputConfirmPasswordRef,
                  value: confirmPassword,
                  onKeyDown: (e: React.KeyboardEvent<HTMLInputElement>) => {
                    if (e.keyCode === 13) {
                      onRegister();
                    }
                  },
                  onChange: (e: React.ChangeEvent<HTMLInputElement>) =>
                    setConfirmPassword(e.target.value),
                }}
              />
            </Row>
            <Row
              width="100%"
              height={40}
              backgroundColor="#20252C"
              hoverBackgroundColor="#1B2025"
              transitionDuration="300ms"
              borderRadius={10}
              border="2px solid #00D398"
              justifyContent="center"
              alignItems="center"
              cursor="pointer"
              marginTop={48}
              props={{
                onClick: onRegister,
              }}
            >
              <Box
                fontFamily="Open Sans"
                fontWeight={600}
                fontSize={14}
                letterSpacing="0.2px"
                color="#00D398"
                textTransform="uppercase"
              >
                Create account
              </Box>
            </Row>
            <Row
              width="100%"
              height={40}
              backgroundColor="#20252C"
              hoverBackgroundColor="#1B2025"
              transitionDuration="300ms"
              borderRadius={10}
              justifyContent="center"
              alignItems="center"
              cursor="pointer"
              marginTop={16}
              props={{ onClick: () => setCreateAccount(false) }}
            >
              <Box
                fontFamily="Open Sans"
                fontWeight={600}
                fontSize={14}
                letterSpacing="0.2px"
                color="#00D398"
                textTransform="uppercase"
              >
                Back
              </Box>
            </Row>
            {error !== null && (
              <Box
                marginTop={16}
                fontFamily="Open Sans"
                fontWeight={600}
                fontSize={14}
                textAlign="center"
                color="#F24971"
              >
                {error}
              </Box>
            )}
          </SignUpContent>
        ) : (
          <SignUpContent>
            <h6>Sign Up</h6>
            <span>
              Create an account to redeem your earnings.
              <br />
              You will also get a $1.00 gift.
            </span>
            <Row
              width="100%"
              height={40}
              backgroundColor="#20252C"
              hoverBackgroundColor="#1B2025"
              transitionDuration="300ms"
              borderRadius={10}
              border="2px solid #00D398"
              justifyContent="center"
              alignItems="center"
              cursor="pointer"
              props={{
                onClick: () => {
                  setCreateAccount(true);
                },
              }}
            >
              <Box
                fontFamily="Open Sans"
                fontWeight={600}
                fontSize={14}
                letterSpacing="0.2px"
                color="#00D398"
                textTransform="uppercase"
              >
                Create a new account
              </Box>
            </Row>

            <Divider>
              <Box width={140} height={1} backgroundColor="#323843" />
              <p>OR</p>
              <Box width={140} height={1} backgroundColor="#323843" />
            </Divider>

            <Row
              width="100%"
              height={40}
              marginBottom={16}
              backgroundColor="#1877F2"
              hoverBackgroundColor="#0D68DD"
              transitionDuration="300ms"
              borderRadius={10}
              justifyContent="center"
              alignItems="center"
              cursor="pointer"
              props={{
                onClick: () => {
                  setError("Coming soon");
                  triggerSignupPixel();
                },
              }}
            >
              <Row>
                <Box
                  component="img"
                  props={{
                    src: require("../images/DataSources/FacebookWhite.svg"),
                  }}
                  width={24}
                  height={24}
                  marginRight={16}
                />
                <Box
                  fontFamily="Open Sans"
                  fontWeight={600}
                  fontSize={14}
                  letterSpacing="0.2px"
                  color="#FFFFFF"
                  marginTop={2}
                  textTransform="uppercase"
                >
                  Login with Facebook
                </Box>
              </Row>
            </Row>
            <Row
              width="100%"
              height={40}
              backgroundColor="rgba(255,255,255,1)"
              hoverBackgroundColor="rgba(255,255,255,0.9)"
              transitionDuration="300ms"
              borderRadius={10}
              justifyContent="center"
              alignItems="center"
              cursor="pointer"
              props={{
                onClick: () => {
                  setError("Coming soon");
                  triggerSignupPixel();
                },
              }}
            >
              <Row>
                <Box
                  component="img"
                  props={{
                    src: require("../images/DataSources/Google.png"),
                  }}
                  width={24}
                  height={24}
                  marginRight={16}
                />
                <Box
                  fontFamily="Open Sans"
                  fontWeight={600}
                  fontSize={14}
                  letterSpacing="0.2px"
                  color="#323843"
                  marginTop={2}
                  textTransform="uppercase"
                >
                  Login with Google
                </Box>
              </Row>
            </Row>
            {error !== null && (
              <Box
                marginTop={16}
                fontFamily="Open Sans"
                fontWeight={600}
                fontSize={14}
                textAlign="center"
                color="#F24971"
              >
                {error}
              </Box>
            )}
          </SignUpContent>
        )}
      </Row>
    </Dialog>
  );
};

const SignUpContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: calc(400px - 64px);
  padding: 48px 32px;

  h6 {
    font-family: "Montserrat", sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    color: #ffffff;
    margin-top: 0px;
    margin-bottom: 24px;
  }
  span {
    font-family: "Open Sans", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 22px;
    text-align: center;
    color: #d6dae1;
    margin-bottom: 48px;
  }
`;

const Divider = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 24px;
  margin-bottom: 24px;
  color: #d6dae1;
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  text-align: center;
`;

export default Signup;
