import React from "react";
import styled from "@emotion/styled";
import Icon from "../images/Category/Headphones.svg";
import IconClose from "../images/icon-close.svg";

const CategoryCard = (props: { name: string; onRemove?: () => void }) => {
  return (
    <Container>
      <Info>{props.name}</Info>
      <CloseButton onClick={props.onRemove}>
        <img src={IconClose} alt="Delete Icon" />
      </CloseButton>
    </Container>
  );
};

export default CategoryCard;

const Container = styled.div`
  height: 44px;
  padding-left: 16px;
  padding-right: 16px;
  border-radius: 10px;
  background-color: #272c35;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #ffffff;
  transition-duration: 350ms;

  &:hover {
    background-color: #323843;
  }
`;

const Info = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`;

const CloseButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px;
  border-radius: 16px;
  cursor: pointer;
  filter: none;
  transition-duration: 300ms;

  &:hover {
    filter: invert(41%) sepia(57%) saturate(2833%) hue-rotate(320deg)
      brightness(98%) contrast(93%);
  }
`;
