import React, { useState, useEffect, useContext } from "react";
import { Col, Row, Box, Grid } from "jsxstyle";
import Dialog from "./Dialog";
import { event } from "react-ga";
import { ModalsContext, AuthContext } from "contexts";
import { triggerLinkDataPixel } from "pixels";

const DataSources = () => {
  const modalsContext = useContext(ModalsContext);
  const authContext = useContext(AuthContext);

  const [openFb, setOpenFb] = useState(false);
  const [openGoogle, setOpenGoogle] = useState(false);
  const [openTwitter, setOpenTwitter] = useState(false);
  const [openLinkedIn, setOpenLinkedIn] = useState(false);
  const [openAmazon, setOpenAmazon] = useState(false);
  const [openSpotify, setOpenSpotify] = useState(false);
  const [openTwitch, setOpenTwitch] = useState(false);
  const [openMint, setOpenMint] = useState(false);

  const [notifyMeFb, setNotifyMeFb] = useState(false);
  const [notifyMeGoogle, setNotifyMeGoogle] = useState(false);
  const [notifyMeTwitter, setNotifyMeTwitter] = useState(false);
  const [notifyMeLinkedIn, setNotifyMeLinkedIn] = useState(false);
  const [notifyMeAmazon, setNotifyMeAmazon] = useState(false);
  const [notifyMeSpotify, setNotifyMeSpotify] = useState(false);
  const [notifyMeTwitch, setNotifyMeTwitch] = useState(false);
  const [notifyMeMint, setNotifyMeMint] = useState(false);

  useEffect(() => {
    const notifyMe =
      notifyMeFb ||
      notifyMeGoogle ||
      notifyMeTwitter ||
      notifyMeLinkedIn ||
      notifyMeAmazon ||
      notifyMeSpotify ||
      notifyMeTwitch ||
      notifyMeMint;

    if (notifyMe) {
      triggerLinkDataPixel();
    }

    if (authContext.token === null && notifyMe) {
      modalsContext.openSignup();
    }
  }, [
    notifyMeFb,
    notifyMeGoogle,
    notifyMeTwitter,
    notifyMeLinkedIn,
    notifyMeAmazon,
    notifyMeSpotify,
    notifyMeTwitch,
    notifyMeMint,
    modalsContext,
    authContext,
  ]);

  return (
    <>
      <Col
        backgroundColor="#1B2025"
        boxShadow="0px 4px 40px rgba(0, 0, 0, 0.15)"
        borderRadius={16}
        overflow="hidden"
        width={424}
      >
        <Row
          height={64}
          backgroundColor="#14171B"
          paddingLeft={32}
          paddingRight={28}
          alignItems="center"
        >
          <Box
            fontFamily="Montserrat"
            fontWeight={600}
            fontSize={18}
            color="#FFF"
            letterSpacing="0.3px"
          >
            Data Sources
          </Box>
        </Row>
        <Col padding={32}>
          <Box
            marginTop={16}
            fontFamily="Montserrat"
            fontSize={16}
            fontWeight={600}
            color="#FFF"
            letterSpacing="0px"
          >
            Which data sources would you like to sync?
          </Box>
          <Box
            fontFamily="Open Sans"
            fontSize={15}
            lineHeight="22px"
            color="#D6DAE1"
            marginTop={8}
            marginBottom={8}
          >
            The more you share, the more you earn. Sync your accounts to
            increase your annual earnings by $20 per account.
          </Box>
          <Grid
            marginTop={32}
            gridTemplateColumns="1fr 1fr"
            gridTemplateRows="44px"
            gridRowGap={16}
            gridColumnGap={24}
          >
            <Row
              height={44}
              backgroundColor="rgba(255,255,255,0.9)"
              hoverBackgroundColor="rgba(255,255,255,1)"
              transitionDuration="300ms"
              borderRadius={10}
              justifyContent="center"
              alignItems="center"
              cursor="pointer"
              props={{
                onClick: () => {
                  setOpenFb(true);
                  event({
                    category: "FacebookIntegration",
                    action: "open_modal",
                  });
                },
              }}
            >
              <Row>
                <Box
                  component="img"
                  props={{ src: require("../images/DataSources/Facebook.png") }}
                  width={24}
                  height={24}
                  marginRight={16}
                />
                <Box
                  fontFamily="Open Sans"
                  fontWeight={600}
                  fontSize={14}
                  letterSpacing="0.2px"
                  color="#20252C"
                  marginTop={2}
                >
                  Facebook
                </Box>
              </Row>
            </Row>
            <Row
              height={44}
              backgroundColor="rgba(255,255,255,0.9)"
              hoverBackgroundColor="rgba(255,255,255,1)"
              transitionDuration="300ms"
              borderRadius={10}
              justifyContent="center"
              alignItems="center"
              cursor="pointer"
              props={{
                onClick: () => {
                  setOpenGoogle(true);
                  event({
                    category: "GoogleIntegration",
                    action: "open_modal",
                  });
                },
              }}
            >
              <Row>
                <Box
                  component="img"
                  props={{ src: require("../images/DataSources/Google.png") }}
                  width={24}
                  height={24}
                  marginRight={16}
                />
                <Box
                  fontFamily="Open Sans"
                  fontWeight={600}
                  fontSize={14}
                  letterSpacing="0.2px"
                  color="#20252C"
                  marginTop={2}
                >
                  Google
                </Box>
              </Row>
            </Row>
            <Row
              height={44}
              backgroundColor="rgba(255,255,255,0.9)"
              hoverBackgroundColor="rgba(255,255,255,1)"
              transitionDuration="300ms"
              borderRadius={10}
              justifyContent="center"
              alignItems="center"
              cursor="pointer"
              props={{
                onClick: () => {
                  setOpenTwitter(true);
                  event({
                    category: "TwitterIntegration",
                    action: "open_modal",
                  });
                },
              }}
            >
              <Row>
                <Box
                  component="img"
                  props={{ src: require("../images/DataSources/Twitter.png") }}
                  width={24}
                  height={24}
                  marginRight={16}
                />
                <Box
                  fontFamily="Open Sans"
                  fontWeight={600}
                  fontSize={14}
                  letterSpacing="0.2px"
                  color="#20252C"
                  marginTop={2}
                >
                  Twitter
                </Box>
              </Row>
            </Row>
            <Row
              height={44}
              backgroundColor="rgba(255,255,255,0.9)"
              hoverBackgroundColor="rgba(255,255,255,1)"
              transitionDuration="300ms"
              borderRadius={10}
              justifyContent="center"
              alignItems="center"
              cursor="pointer"
              props={{
                onClick: () => {
                  setOpenLinkedIn(true);
                  event({
                    category: "LinkedInIntegration",
                    action: "open_modal",
                  });
                },
              }}
            >
              <Row>
                <Box
                  component="img"
                  props={{ src: require("../images/DataSources/LinkedIn.png") }}
                  width={24}
                  height={24}
                  marginRight={16}
                />
                <Box
                  fontFamily="Open Sans"
                  fontWeight={600}
                  fontSize={14}
                  letterSpacing="0.2px"
                  color="#20252C"
                  marginTop={2}
                >
                  LinkedIn
                </Box>
              </Row>
            </Row>
            <Row
              height={44}
              backgroundColor="rgba(255,255,255,0.9)"
              hoverBackgroundColor="rgba(255,255,255,1)"
              transitionDuration="300ms"
              borderRadius={10}
              justifyContent="center"
              alignItems="center"
              cursor="pointer"
              props={{
                onClick: () => {
                  setOpenAmazon(true);
                  event({
                    category: "AmazonIntegration",
                    action: "open_modal",
                  });
                },
              }}
            >
              <Row>
                <Box
                  component="img"
                  props={{ src: require("../images/DataSources/Amazon.png") }}
                  width={24}
                  height={24}
                  marginRight={16}
                />
                <Box
                  fontFamily="Open Sans"
                  fontWeight={600}
                  fontSize={14}
                  letterSpacing="0.2px"
                  color="#20252C"
                  marginTop={2}
                >
                  Amazon
                </Box>
              </Row>
            </Row>
            <Row
              height={44}
              backgroundColor="rgba(255,255,255,0.9)"
              hoverBackgroundColor="rgba(255,255,255,1)"
              transitionDuration="300ms"
              borderRadius={10}
              justifyContent="center"
              alignItems="center"
              cursor="pointer"
              props={{
                onClick: () => {
                  setOpenSpotify(true);
                  event({
                    category: "SpotifyIntegration",
                    action: "open_modal",
                  });
                },
              }}
            >
              <Row>
                <Box
                  component="img"
                  props={{ src: require("../images/DataSources/Spotify.png") }}
                  width={24}
                  height={24}
                  marginRight={16}
                />
                <Box
                  fontFamily="Open Sans"
                  fontWeight={600}
                  fontSize={14}
                  letterSpacing="0.2px"
                  color="#20252C"
                  marginTop={2}
                >
                  Spotify
                </Box>
              </Row>
            </Row>
            <Row
              height={44}
              backgroundColor="rgba(255,255,255,0.9)"
              hoverBackgroundColor="rgba(255,255,255,1)"
              transitionDuration="300ms"
              borderRadius={10}
              justifyContent="center"
              alignItems="center"
              cursor="pointer"
              props={{
                onClick: () => {
                  setOpenTwitch(true);
                  event({
                    category: "TwitchIntegration",
                    action: "open_modal",
                  });
                },
              }}
            >
              <Row>
                <Box
                  component="img"
                  props={{ src: require("../images/DataSources/Twitch.png") }}
                  width={24}
                  height={24}
                  marginRight={16}
                />
                <Box
                  fontFamily="Open Sans"
                  fontWeight={600}
                  fontSize={14}
                  letterSpacing="0.2px"
                  color="#20252C"
                  marginTop={2}
                >
                  Twitch
                </Box>
              </Row>
            </Row>
            <Row
              height={44}
              backgroundColor="rgba(255,255,255,0.9)"
              hoverBackgroundColor="rgba(255,255,255,1)"
              transitionDuration="300ms"
              borderRadius={10}
              justifyContent="center"
              alignItems="center"
              cursor="pointer"
              props={{
                onClick: () => {
                  setOpenMint(true);
                  event({
                    category: "MintIntegration",
                    action: "open_modal",
                  });
                },
              }}
            >
              <Row>
                <Box
                  component="img"
                  props={{ src: require("../images/DataSources/Mint.png") }}
                  width={24}
                  height={24}
                  marginRight={16}
                />
                <Box
                  fontFamily="Open Sans"
                  fontWeight={600}
                  fontSize={14}
                  letterSpacing="0.2px"
                  color="#20252C"
                  marginTop={2}
                >
                  Mint
                </Box>
              </Row>
            </Row>
          </Grid>
        </Col>
      </Col>

      <Dialog width={400} open={openFb} onClose={() => setOpenFb(false)}>
        <Col alignItems="center">
          <Box
            fontFamily="Montserrat"
            fontWeight="bold"
            fontSize={20}
            letterSpacing="0.5px"
            color="#FFF"
            textTransform="uppercase"
            marginTop={24}
          >
            Connect with facebook
          </Box>
          <Box
            fontFamily="Open Sans"
            fontSize={15}
            color="#D6DAE1"
            textAlign="center"
            lineHeight="22px"
            marginTop={24}
            marginH={16}
          >
            Sync your Facebook data to earn more. We’ll complete your profile
            and when an advertiser uses your information to send you a message,
            we’ll pay you. We never access your photos, posts, or any personal
            information.
          </Box>
          <Box
            component="img"
            props={{ src: require("../images/DataSources/FacebookSync.svg") }}
            width={165}
            marginTop={40}
            marginBottom={24}
          />
          {notifyMeFb ? (
            <Box
              fontFamily="Open Sans"
              fontSize={16}
              fontWeight={600}
              color="#00D398"
              textAlign="center"
              marginTop={110}
              marginBottom={30}
            >
              We'll let you know when this feature is ready.
            </Box>
          ) : (
            <Box
              fontFamily="Open Sans"
              fontSize={16}
              fontWeight={600}
              color="#F24971"
              textAlign="center"
            >
              We’re working on building this feature.
              <br />
              Check back in a few weeks.
            </Box>
          )}
          {notifyMeFb ? null : (
            <Row
              width="100%"
              height={40}
              backgroundColor="#20252C"
              hoverBackgroundColor="#1B2025"
              transitionDuration="300ms"
              borderRadius={10}
              border="2px solid #00D398"
              justifyContent="center"
              alignItems="center"
              marginTop={60}
              marginBottom={16}
              cursor="pointer"
              props={{
                onClick: () => {
                  setNotifyMeFb(true);
                  event({
                    category: "FacebookIntegration",
                    action: "notify_me",
                  });
                },
              }}
            >
              <Box
                fontFamily="Open Sans"
                fontWeight={600}
                fontSize={14}
                letterSpacing="0.2px"
                color="#00D398"
                textTransform="uppercase"
              >
                Notify me when is ready
              </Box>
            </Row>
          )}
        </Col>
      </Dialog>

      <Dialog
        width={400}
        open={openGoogle}
        onClose={() => setOpenGoogle(false)}
      >
        <Col alignItems="center">
          <Box
            fontFamily="Montserrat"
            fontWeight="bold"
            fontSize={20}
            letterSpacing="0.5px"
            color="#FFF"
            textTransform="uppercase"
            marginTop={24}
          >
            Connect with Google
          </Box>
          <Box
            fontFamily="Open Sans"
            fontSize={15}
            color="#D6DAE1"
            textAlign="center"
            lineHeight="22px"
            marginTop={24}
          >
            Sync your Google data for more opportunities to earn. We’ll complete
            your profile and when an advertiser uses your information to send
            you a message, we’ll pay you. We never access your personal
            information.
          </Box>
          <Box
            component="img"
            props={{ src: require("../images/DataSources/GoogleSync.svg") }}
            width={165}
            marginTop={40}
            marginBottom={24}
          />
          {notifyMeGoogle ? (
            <Box
              fontFamily="Open Sans"
              fontSize={16}
              fontWeight={600}
              color="#00D398"
              textAlign="center"
              marginTop={110}
              marginBottom={30}
            >
              We'll let you know when this feature is ready.
            </Box>
          ) : (
            <Box
              fontFamily="Open Sans"
              fontSize={16}
              fontWeight={600}
              color="#F24971"
              textAlign="center"
            >
              We’re working on building this feature.
              <br />
              Check back in a few weeks.
            </Box>
          )}
          {notifyMeGoogle ? null : (
            <Row
              width="100%"
              height={40}
              backgroundColor="#20252C"
              hoverBackgroundColor="#1B2025"
              transitionDuration="300ms"
              borderRadius={10}
              border="2px solid #00D398"
              justifyContent="center"
              alignItems="center"
              marginTop={60}
              marginBottom={16}
              cursor="pointer"
              props={{
                onClick: () => {
                  setNotifyMeGoogle(true);
                  event({
                    category: "GoogleIntegration",
                    action: "notify_me",
                  });
                },
              }}
            >
              <Box
                fontFamily="Open Sans"
                fontWeight={600}
                fontSize={14}
                letterSpacing="0.2px"
                color="#00D398"
                textTransform="uppercase"
              >
                Notify me when is ready
              </Box>
            </Row>
          )}
        </Col>
      </Dialog>

      <Dialog
        width={400}
        open={openTwitter}
        onClose={() => setOpenTwitter(false)}
      >
        <Col alignItems="center">
          <Box
            fontFamily="Montserrat"
            fontWeight="bold"
            fontSize={20}
            letterSpacing="0.5px"
            color="#FFF"
            textTransform="uppercase"
            marginTop={24}
          >
            Connect with Twitter
          </Box>
          <Box
            fontFamily="Open Sans"
            fontSize={15}
            color="#D6DAE1"
            textAlign="center"
            lineHeight="22px"
            marginTop={24}
          >
            Sync your Twitter data for more opportunities to earn. We’ll
            complete your profile and when an advertiser uses your information
            to send you a message, we’ll pay you. We never access your personal
            information.
          </Box>
          <Box
            component="img"
            props={{ src: require("../images/DataSources/TwitterSync.svg") }}
            width={165}
            marginTop={40}
            marginBottom={24}
          />
          {notifyMeTwitter ? (
            <Box
              fontFamily="Open Sans"
              fontSize={16}
              fontWeight={600}
              color="#00D398"
              textAlign="center"
              marginTop={110}
              marginBottom={30}
            >
              We'll let you know when this feature is ready.
            </Box>
          ) : (
            <Box
              fontFamily="Open Sans"
              fontSize={16}
              fontWeight={600}
              color="#F24971"
              textAlign="center"
            >
              We’re working on building this feature.
              <br />
              Check back in a few weeks.
            </Box>
          )}
          {notifyMeTwitter ? null : (
            <Row
              width="100%"
              height={40}
              backgroundColor="#20252C"
              hoverBackgroundColor="#1B2025"
              transitionDuration="300ms"
              borderRadius={10}
              border="2px solid #00D398"
              justifyContent="center"
              alignItems="center"
              marginTop={60}
              marginBottom={16}
              cursor="pointer"
              props={{
                onClick: () => {
                  setNotifyMeTwitter(true);
                  event({
                    category: "TwitterIntegration",
                    action: "notify_me",
                  });
                },
              }}
            >
              <Box
                fontFamily="Open Sans"
                fontWeight={600}
                fontSize={14}
                letterSpacing="0.2px"
                color="#00D398"
                textTransform="uppercase"
              >
                Notify me when is ready
              </Box>
            </Row>
          )}
        </Col>
      </Dialog>

      <Dialog
        width={400}
        open={openLinkedIn}
        onClose={() => setOpenLinkedIn(false)}
      >
        <Col alignItems="center">
          <Box
            fontFamily="Montserrat"
            fontWeight="bold"
            fontSize={20}
            letterSpacing="0.5px"
            color="#FFF"
            textTransform="uppercase"
            marginTop={24}
          >
            Connect with LinkedIn
          </Box>
          <Box
            fontFamily="Open Sans"
            fontSize={15}
            color="#D6DAE1"
            textAlign="center"
            lineHeight="22px"
            marginTop={24}
          >
            Sync your LinkedIn data for more opportunities to earn. We’ll
            complete your profile and when an advertiser uses your information
            to send you a message, we’ll pay you. We never access your personal
            information.
          </Box>
          <Box
            component="img"
            props={{ src: require("../images/DataSources/LinkedinSync.svg") }}
            width={165}
            marginTop={40}
            marginBottom={24}
          />
          {notifyMeLinkedIn ? (
            <Box
              fontFamily="Open Sans"
              fontSize={16}
              fontWeight={600}
              color="#00D398"
              textAlign="center"
              marginTop={110}
              marginBottom={30}
            >
              We'll let you know when this feature is ready.
            </Box>
          ) : (
            <Box
              fontFamily="Open Sans"
              fontSize={16}
              fontWeight={600}
              color="#F24971"
              textAlign="center"
            >
              We’re working on building this feature.
              <br />
              Check back in a few weeks.
            </Box>
          )}
          {notifyMeLinkedIn ? null : (
            <Row
              width="100%"
              height={40}
              backgroundColor="#20252C"
              hoverBackgroundColor="#1B2025"
              transitionDuration="300ms"
              borderRadius={10}
              border="2px solid #00D398"
              justifyContent="center"
              alignItems="center"
              marginTop={60}
              marginBottom={16}
              cursor="pointer"
              props={{
                onClick: () => {
                  setNotifyMeLinkedIn(true);
                  event({
                    category: "LinkedInIntegration",
                    action: "notify_me",
                  });
                },
              }}
            >
              <Box
                fontFamily="Open Sans"
                fontWeight={600}
                fontSize={14}
                letterSpacing="0.2px"
                color="#00D398"
                textTransform="uppercase"
              >
                Notify me when is ready
              </Box>
            </Row>
          )}
        </Col>
      </Dialog>

      <Dialog
        width={400}
        open={openAmazon}
        onClose={() => setOpenAmazon(false)}
      >
        <Col alignItems="center">
          <Box
            fontFamily="Montserrat"
            fontWeight="bold"
            fontSize={20}
            letterSpacing="0.5px"
            color="#FFF"
            textTransform="uppercase"
            marginTop={24}
          >
            Connect with Amazon
          </Box>
          <Box
            fontFamily="Open Sans"
            fontSize={15}
            color="#D6DAE1"
            textAlign="center"
            lineHeight="22px"
            marginTop={24}
          >
            Sync your Amazon data for more opportunities to earn. We’ll complete
            your profile and when an advertiser uses your information to send
            you a message, we’ll pay you. We never access your personal
            information.
          </Box>
          <Box
            component="img"
            props={{ src: require("../images/DataSources/AmazonSync.svg") }}
            width={165}
            marginTop={40}
            marginBottom={24}
          />
          {notifyMeAmazon ? (
            <Box
              fontFamily="Open Sans"
              fontSize={16}
              fontWeight={600}
              color="#00D398"
              textAlign="center"
              marginTop={110}
              marginBottom={30}
            >
              We'll let you know when this feature is ready.
            </Box>
          ) : (
            <Box
              fontFamily="Open Sans"
              fontSize={16}
              fontWeight={600}
              color="#F24971"
              textAlign="center"
            >
              We’re working on building this feature.
              <br />
              Check back in a few weeks.
            </Box>
          )}
          {notifyMeAmazon ? null : (
            <Row
              width="100%"
              height={40}
              backgroundColor="#20252C"
              hoverBackgroundColor="#1B2025"
              transitionDuration="300ms"
              borderRadius={10}
              border="2px solid #00D398"
              justifyContent="center"
              alignItems="center"
              marginTop={60}
              marginBottom={16}
              cursor="pointer"
              props={{
                onClick: () => {
                  setNotifyMeAmazon(true);
                  event({
                    category: "AmazonIntegration",
                    action: "notify_me",
                  });
                },
              }}
            >
              <Box
                fontFamily="Open Sans"
                fontWeight={600}
                fontSize={14}
                letterSpacing="0.2px"
                color="#00D398"
                textTransform="uppercase"
              >
                Notify me when is ready
              </Box>
            </Row>
          )}
        </Col>
      </Dialog>

      <Dialog
        width={400}
        open={openSpotify}
        onClose={() => setOpenSpotify(false)}
      >
        <Col alignItems="center">
          <Box
            fontFamily="Montserrat"
            fontWeight="bold"
            fontSize={20}
            letterSpacing="0.5px"
            color="#FFF"
            textTransform="uppercase"
            marginTop={24}
          >
            Connect with Spotify
          </Box>
          <Box
            fontFamily="Open Sans"
            fontSize={15}
            color="#D6DAE1"
            textAlign="center"
            lineHeight="22px"
            marginTop={24}
          >
            Sync your Spotify data for more opportunities to earn. We’ll
            complete your profile and when an advertiser uses your information
            to send you a message, we’ll pay you. We never access your personal
            information.
          </Box>
          <Box
            component="img"
            props={{ src: require("../images/DataSources/SpotifySync.svg") }}
            width={165}
            marginTop={40}
            marginBottom={24}
          />
          {notifyMeSpotify ? (
            <Box
              fontFamily="Open Sans"
              fontSize={16}
              fontWeight={600}
              color="#00D398"
              textAlign="center"
              marginTop={110}
              marginBottom={30}
            >
              We'll let you know when this feature is ready.
            </Box>
          ) : (
            <Box
              fontFamily="Open Sans"
              fontSize={16}
              fontWeight={600}
              color="#F24971"
              textAlign="center"
            >
              We’re working on building this feature.
              <br />
              Check back in a few weeks.
            </Box>
          )}
          {notifyMeSpotify ? null : (
            <Row
              width="100%"
              height={40}
              backgroundColor="#20252C"
              hoverBackgroundColor="#1B2025"
              transitionDuration="300ms"
              borderRadius={10}
              border="2px solid #00D398"
              justifyContent="center"
              alignItems="center"
              marginTop={60}
              marginBottom={16}
              cursor="pointer"
              props={{
                onClick: () => {
                  setNotifyMeSpotify(true);
                  event({
                    category: "SpotifyIntegration",
                    action: "notify_me",
                  });
                },
              }}
            >
              <Box
                fontFamily="Open Sans"
                fontWeight={600}
                fontSize={14}
                letterSpacing="0.2px"
                color="#00D398"
                textTransform="uppercase"
              >
                Notify me when is ready
              </Box>
            </Row>
          )}
        </Col>
      </Dialog>

      <Dialog
        width={400}
        open={openTwitch}
        onClose={() => setOpenTwitch(false)}
      >
        <Col alignItems="center">
          <Box
            fontFamily="Montserrat"
            fontWeight="bold"
            fontSize={20}
            letterSpacing="0.5px"
            color="#FFF"
            textTransform="uppercase"
            marginTop={24}
          >
            Connect with Twitch
          </Box>
          <Box
            fontFamily="Open Sans"
            fontSize={15}
            color="#D6DAE1"
            textAlign="center"
            lineHeight="22px"
            marginTop={24}
          >
            Sync your Twitch data for more opportunities to earn. We’ll complete
            your profile and when an advertiser uses your information to send
            you a message, we’ll pay you. We never access your personal
            information.
          </Box>
          <Box
            component="img"
            props={{ src: require("../images/DataSources/TwitchSync.png") }}
            width={165}
            marginTop={40}
            marginBottom={24}
          />
          {notifyMeTwitch ? (
            <Box
              fontFamily="Open Sans"
              fontSize={16}
              fontWeight={600}
              color="#00D398"
              textAlign="center"
              marginTop={110}
              marginBottom={30}
            >
              We'll let you know when this feature is ready.
            </Box>
          ) : (
            <Box
              fontFamily="Open Sans"
              fontSize={16}
              fontWeight={600}
              color="#F24971"
              textAlign="center"
            >
              We’re working on building this feature.
              <br />
              Check back in a few weeks.
            </Box>
          )}
          {notifyMeTwitch ? null : (
            <Row
              width="100%"
              height={40}
              backgroundColor="#20252C"
              hoverBackgroundColor="#1B2025"
              transitionDuration="300ms"
              borderRadius={10}
              border="2px solid #00D398"
              justifyContent="center"
              alignItems="center"
              marginTop={60}
              marginBottom={16}
              cursor="pointer"
              props={{
                onClick: () => {
                  setNotifyMeTwitch(true);
                  event({
                    category: "TwitchIntegration",
                    action: "notify_me",
                  });
                },
              }}
            >
              <Box
                fontFamily="Open Sans"
                fontWeight={600}
                fontSize={14}
                letterSpacing="0.2px"
                color="#00D398"
                textTransform="uppercase"
              >
                Notify me when is ready
              </Box>
            </Row>
          )}
        </Col>
      </Dialog>

      <Dialog width={400} open={openMint} onClose={() => setOpenMint(false)}>
        <Col alignItems="center">
          <Box
            fontFamily="Montserrat"
            fontWeight="bold"
            fontSize={20}
            letterSpacing="0.5px"
            color="#FFF"
            textTransform="uppercase"
            marginTop={24}
          >
            Connect with Mint
          </Box>
          <Box
            fontFamily="Open Sans"
            fontSize={15}
            color="#D6DAE1"
            textAlign="center"
            lineHeight="22px"
            marginTop={24}
          >
            Sync your Mint data for more opportunities to earn. We’ll complete
            your profile and when an advertiser uses your information to send
            you a message, we’ll pay you. We never access your personal
            information.
          </Box>
          <Box
            component="img"
            props={{ src: require("../images/DataSources/MintSync.svg") }}
            width={165}
            marginTop={40}
            marginBottom={24}
          />
          {notifyMeMint ? (
            <Box
              fontFamily="Open Sans"
              fontSize={16}
              fontWeight={600}
              color="#00D398"
              textAlign="center"
              marginTop={110}
              marginBottom={30}
            >
              We'll let you know when this feature is ready.
            </Box>
          ) : (
            <Box
              fontFamily="Open Sans"
              fontSize={16}
              fontWeight={600}
              color="#F24971"
              textAlign="center"
            >
              We’re working on building this feature.
              <br />
              Check back in a few weeks.
            </Box>
          )}
          {notifyMeMint ? null : (
            <Row
              width="100%"
              height={40}
              backgroundColor="#20252C"
              hoverBackgroundColor="#1B2025"
              transitionDuration="300ms"
              borderRadius={10}
              border="2px solid #00D398"
              justifyContent="center"
              alignItems="center"
              marginTop={60}
              marginBottom={16}
              cursor="pointer"
              props={{
                onClick: () => {
                  setNotifyMeMint(true);
                  event({
                    category: "MintIntegration",
                    action: "notify_me",
                  });
                },
              }}
            >
              <Box
                fontFamily="Open Sans"
                fontWeight={600}
                fontSize={14}
                letterSpacing="0.2px"
                color="#00D398"
                textTransform="uppercase"
              >
                Notify me when is ready
              </Box>
            </Row>
          )}
        </Col>
      </Dialog>
    </>
  );
};

export default DataSources;
