import React from "react";
import { Row, Box } from "jsxstyle";
import { useSpring, animated } from "react-spring";

const OdometerCell = (props: {
  char: string;
  height: number;
  color?: string;
}) => {
  const { height } = props;
  const num = parseInt(props.char, 10);
  const spring = useSpring({
    top: -(height * num),
    color: props.color || "#FFFFFF",
  });
  return (
    <animated.div
      style={{
        display: "flex",
        flexDirection: "column",
        position: "absolute",
        ...spring,
      }}
    >
      {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9].map((n) => (
        <Box
          fontFamily="Open Sans"
          fontWeight="bold"
          key={n}
          style={{
            height: height,
            fontSize: height * 0.85,
          }}
        >
          {n}
        </Box>
      ))}
    </animated.div>
  );
};

const Odometer = (props: {
  value: string;
  height?: number;
  className?: string;
  color?: string;
}) => {
  const spring = useSpring({ color: props.color || "#FFFFFF" });
  const height = props.height || 70;
  return (
    <Row
      height={height}
      overflow="hidden"
      className={props.className ? props.className : ""}
    >
      {props.value.split("").map((char) =>
        char === "." || char === "$" ? (
          <animated.div
            style={{
              fontFamily: "Open Sans",
              fontWeight: "bold",
              fontSize: height * 0.85,
              ...spring,
            }}
          >
            {char}
          </animated.div>
        ) : (
          <Box position="relative" style={{ width: height / 2 }}>
            <OdometerCell char={char} height={height} color={props.color} />
          </Box>
        )
      )}
    </Row>
  );
};

export default Odometer;
