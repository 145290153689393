import React from "react";

export const AuthContext = React.createContext<{
  token: string | null;
  setToken: (v: string) => void;
  logout: () => void;
}>({
  token: null,
  setToken: () => {},
  logout: () => {},
});

export const ModalsContext = React.createContext<{
  displayLogin: boolean;
  openLogin: () => void;
  displaySignup: boolean;
  openSignup: () => void;
}>({
  displayLogin: false,
  openLogin: () => {},
  displaySignup: false,
  openSignup: () => {},
});

export const InterestsContext = React.createContext<{
  like: Array<string>;
  dislike: Array<string>;
  addInterestLike: (v: string) => void;
  addMultipleLike: (v: Array<string>) => void;
  removeInterestLike: (v: string) => void;
  addInterestDislike: (v: string) => void;
  addMultipleDislike: (v: Array<string>) => void;
  removeInterestDislike: (v: string) => void;
}>({
  like: [],
  dislike: [],
  addInterestLike: () => {},
  addMultipleLike: () => {},
  removeInterestLike: () => {},
  addInterestDislike: () => {},
  addMultipleDislike: () => {},
  removeInterestDislike: () => {},
});

export const DataContext = React.createContext<{
  wallet: number;
  earnings: number;
  profileCompletion: number;
  sellMyData: boolean;
  toggleSellData: () => void;
}>({
  wallet: 0,
  earnings: 0,
  profileCompletion: 0,
  sellMyData: true,
  toggleSellData: () => {},
});

export type DemographicState = "inferred" | "verified" | "unknown";

export interface Demographic {
  id: string;
  state: DemographicState;
  field: string;
  value: string;
  options: Array<string>;
}

export const DemographicContext = React.createContext<{
  items: Array<Demographic>;
  setDemographicState: (id: string, s: DemographicState) => void;
  setValue: (id: string, value: string) => void;
}>({
  items: [],
  setDemographicState: () => {},
  setValue: () => {},
});
