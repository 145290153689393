import React, { useRef, useState, useContext } from "react";
import styled from "@emotion/styled";
import { Box, Row } from "jsxstyle";
import { motion } from "framer-motion";
import Popper from "@material-ui/core/Popper";
import Fade from "@material-ui/core/Fade";
import Chevron from "../images/icon-chevron-down.svg";
import InfoIcon from "../images/icon-info.svg";
import WalletIcon from "../images/icon-wallet.svg";
import { DataContext, ModalsContext, AuthContext } from "contexts";
import Switch from "./Switch";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import { Theme, makeStyles } from "@material-ui/core/styles";
import Tooltip, { TooltipProps } from "@material-ui/core/Tooltip";
import Zoom from "@material-ui/core/Zoom";
import Odometer from "./Odometer";
import { event } from "react-ga";

const useStylesBootstrap = makeStyles((theme: Theme) => ({
  arrow: {
    color: "#14171B",
  },
  tooltip: {
    border: "1px solid #323843",
    backgroundColor: "#14171B",
    fontFamily: "Open Sans",
    fontSize: "11px",
  },
}));

function CustomTooltip(props: TooltipProps) {
  const classes = useStylesBootstrap();

  return <Tooltip arrow classes={classes} {...props} />;
}

const Wallet = () => {
  const ref = useRef<HTMLDivElement>();
  const [open, setOpen] = useState(false);
  const [cashOut, setCashOut] = useState(false);
  const modalsContext = useContext(ModalsContext);
  const authContext = useContext(AuthContext);
  const dataContext = useContext(DataContext);
  const formatter = useRef(
    Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: 2,
    })
  );

  return (
    <ClickAwayListener
      onClickAway={() => {
        setOpen(false);
        setCashOut(false);
      }}
    >
      <div>
        <Box
          props={{
            onClick: () => {
              setOpen((o) => !o);
              setCashOut(false);
              event({
                category: "Wallet",
                action: "open_modal",
              });
            },
            ref: (r) => {
              ref.current = r || undefined;
            },
          }}
        >
          <Container>
            <WalletBalance>
              Wallet Balance
              <Balance>{formatter.current.format(dataContext.wallet)}</Balance>
            </WalletBalance>
            <Divider />
            <WalletBalance>
              Est. Earnings *
              <Balance
                style={{ color: dataContext.sellMyData ? "white" : "red" }}
              >
                <Odometer
                  value={formatter.current.format(dataContext.earnings)}
                  height={21}
                  color={dataContext.sellMyData ? "#FFFFFF" : "#F24971"}
                />
              </Balance>
            </WalletBalance>
            <Divider style={{ marginRight: 16 }} />
            <motion.div animate={{ rotate: open ? "180deg" : "0deg" }}>
              <DropdownIcon src={Chevron} alt="Chevron Icon" />
            </motion.div>
          </Container>
        </Box>
        <Popper
          open={open}
          anchorEl={ref.current}
          placement="bottom-end"
          style={{
            zIndex: 1000,
          }}
          transition
        >
          {({ TransitionProps }) => (
            <Fade {...TransitionProps} timeout={350}>
              <PopperContainer>
                <DataSwitch>
                  <Row
                    cursor="pointer"
                    props={{ onClick: dataContext.toggleSellData }}
                  >
                    <Switch checked={dataContext.sellMyData} />
                    <Box marginLeft={8}>Sell my data for me</Box>
                  </Row>
                  <CustomTooltip
                    title="When advertisers use your information to send you messages, we take a 5% platform fee, and pass the rest to you. Turn this on to start earning money."
                    arrow
                    TransitionComponent={Zoom}
                    placement="bottom-end"
                    style={{ zIndex: 2000 }}
                  >
                    <img
                      src={InfoIcon}
                      alt="Information Icon"
                      style={{ cursor: "pointer" }}
                    />
                  </CustomTooltip>
                </DataSwitch>
                <Box
                  width="calc(100%)"
                  height={1}
                  paddingLeft={24}
                  marginRight={-24}
                  marginV={20}
                  backgroundColor="#323843"
                />
                <Subtitle>My wallet</Subtitle>
                <BalanceRow>
                  <WalletBalanceDropdown>
                    <BalanceDropdown>
                      {formatter.current.format(dataContext.wallet)}
                    </BalanceDropdown>
                    <Label>Wallet Balance</Label>
                  </WalletBalanceDropdown>
                  <WalletBalanceDropdown>
                    <BalanceDropdown
                      style={{
                        color: dataContext.sellMyData ? "white" : "red",
                      }}
                    >
                      <Odometer
                        value={formatter.current.format(dataContext.earnings)}
                        height={41}
                        color={dataContext.sellMyData ? "#FFFFFF" : "#F24971"}
                      />
                    </BalanceDropdown>
                    <Label>Est. Annual Earnings *</Label>
                  </WalletBalanceDropdown>
                </BalanceRow>
                <Box
                  width="100%"
                  props={{
                    onClick: () => {
                      setCashOut(true);
                      if (authContext.token === null) {
                        modalsContext.openSignup();
                      }
                      event({
                        category: "Wallet",
                        action: "clicked_cash_out",
                      });
                    },
                  }}
                >
                  <CashOutButton>
                    <img
                      src={WalletIcon}
                      alt="Wallet Icon"
                      style={{ marginRight: 10 }}
                    />
                    Cash Out
                  </CashOutButton>
                </Box>
                {cashOut ? (
                  <Box
                    textAlign="center"
                    fontFamily="Open Sans"
                    fontWeight={600}
                    fontSize={14}
                    color="#F24971"
                    maxWidth={327}
                    marginTop={8}
                  >
                    Your wallet balance is $0.00
                  </Box>
                ) : null}
                <Disclaimer>
                  * Estimated annual earnings are based on what advertisers are
                  paying for data like yours. The more complete your profile is,
                  the more you can expect to earn.
                </Disclaimer>
              </PopperContainer>
            </Fade>
          )}
        </Popper>
      </div>
    </ClickAwayListener>
  );
};

export default Wallet;

const Container = styled.div`
  padding: 4px 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: rgba(39, 44, 53, 0.6);
  border-radius: 10px;
  cursor: pointer;
  transition-duration: 300ms;

  &:hover {
    background: rgba(39, 44, 53, 1);
  }
`;
const WalletBalance = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin-left: 18px;
  margin-right: 18px;
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  font-size: 10px;
  line-height: 14px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #f1f4f8;
`;
const Balance = styled.div`
  margin-top: 0px;
  font-family: "Open Sans", sans-serif;
  font-weight: 700;
  font-size: 18px;
  line-height: 21px;
  color: #00d398;
`;
const Divider = styled.div`
  width: 2px;
  height: 36px;
  background-color: #323843;
`;
const DropdownIcon = styled.img`
  width: 24px;
  height: 24px;
  opacity: 0.7;
  transform: rotate(0deg);
  transition: 300ms ease-in;

  &:hover {
    opacity: 1;
  }
`;

const PopperContainer = styled.div`
  min-width: 375px;
  margin-top: 4px;
  padding-top: 24px;
  padding-bottom: 32px;
  padding-left: 24px;
  padding-right: 24px;
  background-color: rgba(27, 32, 37, 0.98);
  border: 1px solid #272c35;
  box-sizing: border-box;
  box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.5);
  border-radius: 16px;
  color: #ffffff;
`;
const DataSwitch = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const Subtitle = styled.span`
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.3px;
  color: #ffffff;
  text-transform: uppercase;
`;
const BalanceRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-top: 16px;
  margin-bottom: 24px;
`;
const WalletBalanceDropdown = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
const BalanceDropdown = styled.div`
  margin-top: 0px;
  font-family: "Open Sans", sans-serif;
  font-weight: 700;
  font-size: 30px;
  line-height: 41px;
  color: #00d398;
`;
const Label = styled.span`
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  font-size: 13px;
  text-align: center;
  color: #9ba6b1;
`;
const CashOutButton = styled.div`
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #323843;
  border-radius: 10px;
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.2px;
  text-transform: uppercase;
  color: #ffffff;
  cursor: pointer;
  transition-duration: 300ms;

  &:hover {
    background-color: #293242;
  }
`;
const Disclaimer = styled.div`
  max-width: 325px;
  margin-top: 24px;
  margin-bottom: -16px;
  margin-left: auto;
  margin-right: auto;
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  font-size: 12px;
  line-height: 17px;
  color: #d6dae1;
  text-align: left;
`;
