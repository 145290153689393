import React, { useContext, useRef, useState } from "react";
import styled from "@emotion/styled";
import CheckIcon from "../images/checkIcon.svg";
import DeleteIcon from "../images/deleteIcon.svg";
import EditIcon from "../images/editIcon.svg";
import UndoIcon from "../images/undoIcon.svg";
import { Theme, makeStyles } from "@material-ui/core/styles";
import Tooltip, { TooltipProps } from "@material-ui/core/Tooltip";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Popper from "@material-ui/core/Popper";
import Fade from "@material-ui/core/Fade";

import { Col, Row, Box } from "jsxstyle";
import { Demographic, DemographicContext } from "contexts";
import { event } from "react-ga";

const useStylesBootstrap = makeStyles((theme: Theme) => ({
  arrow: {
    color: "rgba(0,0,0,0)",
  },
  tooltip: {
    border: "1px solid #323843",
    backgroundColor: "#14171B",
    fontFamily: "Open Sans",
    fontSize: "11px",
  },
}));

function CustomTooltip(props: TooltipProps) {
  const classes = useStylesBootstrap();

  return <Tooltip arrow classes={classes} {...props} />;
}

const DemographicsCard = (props: { item: Demographic }) => {
  const ctx = useContext(DemographicContext);
  const [openEdit, setOpenEdit] = useState(false);
  const ref = useRef<HTMLDivElement>(null);
  return (
    <>
      <Container
        color={(() => {
          switch (props.item.state) {
            case "unknown":
              return "#FF5981";
            case "inferred":
              return "#BCBFC6";
            case "verified":
              return "#00D398";
          }
        })()}
      >
        <Col>
          <Label>{props.item.field}</Label>
          <Info>
            {props.item.state === "unknown" ? "N/A" : props.item.value}
          </Info>
        </Col>
        <Row>
          <Row marginRight={7}>
            {props.item.state === "inferred" ? (
              <>
                <ClickAwayListener onClickAway={() => setOpenEdit(false)}>
                  <div>
                    <CustomTooltip title="You’re wrong, let me tell you.">
                      <ActionIcon
                        onClick={() => {
                          setOpenEdit(true);
                          event({
                            category: "Demographics",
                            action: "edit_item",
                          });
                        }}
                        ref={ref}
                      >
                        <img src={EditIcon} alt="Edit Icon" />
                      </ActionIcon>
                    </CustomTooltip>
                    <Popper
                      open={openEdit}
                      anchorEl={ref.current}
                      placement="bottom-start"
                      style={{
                        zIndex: 1000,
                      }}
                      transition
                    >
                      {({ TransitionProps }) => (
                        <Fade {...TransitionProps} timeout={350}>
                          <Col
                            width={300}
                            maxHeight={400}
                            backgroundColor="#323843"
                            borderRadius={10}
                            overflow="auto"
                            boxShadow="0px 4px 30px rgba(0, 0, 0, 0.25)"
                          >
                            {props.item.options.map((option) => (
                              <Row
                                flexShrink={0}
                                height={44}
                                hoverBackgroundColor="#272C35"
                                alignItems="center"
                                paddingH={16}
                                cursor="pointer"
                                props={{
                                  onClick: () => {
                                    ctx.setValue(props.item.id, option);
                                    setOpenEdit(false);
                                    ctx.setDemographicState(
                                      props.item.id,
                                      "verified"
                                    );
                                  },
                                }}
                              >
                                <Box
                                  fontFamily="Montserrat"
                                  fontSize={14}
                                  color="#FFF"
                                  marginLeft={16}
                                >
                                  {option}
                                </Box>
                              </Row>
                            ))}
                          </Col>
                        </Fade>
                      )}
                    </Popper>
                  </div>
                </ClickAwayListener>
                <CustomTooltip title="Yes">
                  <VerifyIcon
                    onClick={() => {
                      ctx.setDemographicState(props.item.id, "verified");
                      event({
                        category: "Demographics",
                        action: "verify_item",
                      });
                    }}
                  >
                    <img src={CheckIcon} alt="Verify Icon" />
                  </VerifyIcon>
                </CustomTooltip>
                <CustomTooltip title="Don't track">
                  <RemoveIcon
                    onClick={() => {
                      ctx.setDemographicState(props.item.id, "unknown");
                      event({
                        category: "Demographics",
                        action: "remove_item",
                      });
                    }}
                  >
                    <img src={DeleteIcon} alt="Delete Icon" />
                  </RemoveIcon>
                </CustomTooltip>
              </>
            ) : (
              <CustomTooltip title="Restore to inferred">
                <ActionIcon
                  onClick={() => {
                    ctx.setDemographicState(props.item.id, "inferred");
                    event({
                      category: "Demographics",
                      action: "restore_to_inferred_item",
                    });
                  }}
                >
                  <img src={UndoIcon} alt="Undo Icon" />
                </ActionIcon>
              </CustomTooltip>
            )}
          </Row>
          <Row
            marginTop={6}
            height={18}
            backgroundColor={(() => {
              switch (props.item.state) {
                case "unknown":
                  return "#FF5981";
                case "inferred":
                  return "#BCBFC6";
                case "verified":
                  return "#00D398";
              }
            })()}
            borderRadius={11}
            fontFamily="Open Sans"
            fontWeight={600}
            paddingV={2}
            paddingH={10}
            fontSize={13}
            color="#14171b"
          >
            {(() => {
              switch (props.item.state) {
                case "unknown":
                  return "Unknown";
                case "inferred":
                  return "Is this correct?";
                case "verified":
                  return "Verified";
              }
            })()}
          </Row>
        </Row>
      </Container>
    </>
  );
};

export default DemographicsCard;

const Container = styled.div<{ color: string }>`
  height: 50px;
  border-left: 4px solid ${(props) => props.color};
  padding-left: 16px;
  padding-right: 12px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  background-color: #272c35;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const Label = styled.span`
  font-family: "Montserrat", sans-serif;
  font-size: 10px;
  font-weight: 500;
  letter-spacing: 0.2px;
  color: rgba(255, 255, 255, 0.6);
  margin-bottom: 4px;
  text-transform: uppercase;
  text-overflow: ellipsis;
  max-lines: 1;
  white-space: nowrap;
`;
const Info = styled.span`
  max-width: 150px;
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  font-size: 14px;
  color: #ffffff;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const ActionIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px;
  margin-right: 0px;
  border-radius: 10px;
  cursor: pointer;
  filter: none;
  transition-duration: 300ms;

  &:hover {
    background-color: #323843;
  }
`;

const VerifyIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px;
  margin-right: 0px;
  border-radius: 10px;
  cursor: pointer;
  filter: none;
  transition-duration: 300ms;

  &:hover {
    background-color: rgba(0, 211, 152, 0.1);
  }
`;

const RemoveIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px;
  border-radius: 10px;
  cursor: pointer;
  filter: none;
  transition-duration: 300ms;

  &:hover {
    background-color: rgba(242, 73, 113, 0.1);
  }
`;
